// CustomerKeepsProductPaidActionButton.tsx
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ActionButton from '../../../../components/ActionButton';
import { useReturnItemCustomerKeepsProductPaid } from '../../../../api/automator/returns/useReturnItemCustomerKeepsProductPaid';
import { ReturnItemStatus } from '../../../../domain/automator/returns/ReturnItemStatus';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface CustomerKeepsProductPaidActionButtonProps {
  returnItemId: number;
  currentStatus: ReturnItemStatus;
  onSuccess?: () => void;
  showLabel?: boolean;
}

const CustomerKeepsProductPaidActionButton = ({
  returnItemId,
  currentStatus,
  onSuccess,
  showLabel,
}: CustomerKeepsProductPaidActionButtonProps) => {
  const { t } = useTranslation();
  const {
    mutate: returnItemCustomerKeepsProductPaid,
    isLoading: returnItemCustomerKeepsProductPaidIsLoading,
  } = useReturnItemCustomerKeepsProductPaid(returnItemId);

  const handleClick = () => {
    returnItemCustomerKeepsProductPaid(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.returns.actions.set_to_customer_keeps_product_paid));
        onSuccess?.();
      },
    });
  };

  return (
    <ActionButton
      icon={<CardGiftcardIcon />}
      label={
        showLabel ? t(tokens.automator.returns.actions.set_to_customer_keeps_product_paid) : ''
      }
      onClick={handleClick}
      isDisabled={
        currentStatus === ReturnItemStatus.REPAIR || currentStatus === ReturnItemStatus.EXCHANGE
      }
      isLoading={returnItemCustomerKeepsProductPaidIsLoading}
      variant={
        currentStatus === ReturnItemStatus.CUSTOMER_KEEPS_PRODUCT_PAID ? 'contained' : 'outlined'
      }
      size="small"
    />
  );
};

export default CustomerKeepsProductPaidActionButton;
