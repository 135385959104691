import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import ActionButton from '../../../../components/ActionButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Restock from '../../../../domain/automator/stock/Restock';
import { toast } from 'react-hot-toast';
import { useEditRestock } from '../../../../api/automator/stock/useEditRestock';
import { useFetchRestock } from '../../../../api/automator/stock/useFetchRestock';
import { RestockItemStatus } from '../../../../domain/automator/stock/RestockItemStatus';

interface EditRestockFormProps {
  restockId: number;
  onSave?: () => void;
}

export const EditRestockForm = ({ restockId, onSave }: EditRestockFormProps) => {
  const { t } = useTranslation();

  const { data: restock, isLoading } = useFetchRestock(restockId);

  const { mutate: doEditRestock, isLoading: isLoadingEditRestock } = useEditRestock(restockId);

  const onEdit = (daysUntilDelivery: number) => {
    doEditRestock(
      {
        expected_delivery_date: new Date(
          new Date().getTime() + daysUntilDelivery * 24 * 60 * 60 * 1000
        ).toISOString(),
      },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.stock.restock_dialog.restock_created));
          onSave?.();
        },
      }
    );
  };

  const determineDaysUntilDelivery = (restock: Restock): number => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const expectedDeliveryDate = new Date(restock.expectedDeliveryDate);
    expectedDeliveryDate.setHours(0, 0, 0, 0);

    const msInADay = 1000 * 60 * 60 * 24;
    const diffTime = Math.abs(today.getTime() - expectedDeliveryDate.getTime());

    if (diffTime <= 0) {
      return 0;
    }

    const days = Math.round(diffTime / msInADay);

    if (days <= 0) {
      return 0;
    }

    return days;
  };

  const [daysUntilDelivery, setDaysUntilDelivery] = useState<number>(-1);
  const [deliveryStatus, setDeliveryStatus] = useState<RestockItemStatus>(
    RestockItemStatus.DELIVERED
  );

  useEffect(() => {
    if (deliveryStatus == RestockItemStatus.DELIVERED && daysUntilDelivery == -1 && restock) {
      setDaysUntilDelivery(determineDaysUntilDelivery(restock!));
      setDeliveryStatus(
        determineDaysUntilDelivery(restock!) > 0
          ? RestockItemStatus.ORDERED
          : RestockItemStatus.DELIVERED
      );
    }
  }, [deliveryStatus, restock]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <Stack
        direction="row"
        gap={2}
      >
        <ToggleButtonGroup
          value={deliveryStatus}
          exclusive
          onChange={(_, newValue) => {
            if (newValue) setDeliveryStatus(newValue);
          }}
          aria-label="delivery status"
        >
          <ToggleButton
            value={RestockItemStatus.DELIVERED}
            aria-label="delivered"
          >
            {t(tokens.automator.stock.product_stock_status.delivered)}
          </ToggleButton>
          <ToggleButton
            value={RestockItemStatus.ORDERED}
            aria-label="ordered"
          >
            {t(tokens.automator.stock.product_stock_status.ordered)}
          </ToggleButton>
        </ToggleButtonGroup>

        {deliveryStatus == RestockItemStatus.ORDERED && (
          <Box width={300}>
            <TextField
              label={t(tokens.automator.stock.restock_dialog.days_until_delivery)}
              variant="outlined"
              value={daysUntilDelivery}
              onChange={(e) => setDaysUntilDelivery(parseInt(e.target.value))}
            />
          </Box>
        )}
      </Stack>

      <ActionButton
        color="primary"
        label={t(tokens.automator.stock.restock_dialog.save)}
        onClick={() => onEdit(daysUntilDelivery)}
        isLoading={isLoadingEditRestock}
      />
    </Stack>
  );
};
