import { RESTOCKS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';
import { RestockItemStatus } from '../../../domain/automator/stock/RestockItemStatus';
import Restock from '../../../domain/automator/stock/Restock';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import { InventoryStocksQueryKey } from '../../queryKeys/InventoryStocksQueryKey';
import { InventoryItemStockAdjustmentsQueryKey } from '../../queryKeys/InventoryItemStockAdjustmentsQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';
import { InventoryItemSuppliersQueryKey } from '../../queryKeys/InventoryItemSuppliersQueryKey';
import { InventoryItemRestockItemsQueryKey } from '../../queryKeys/InventoryItemRestockItemsQueryKey';
import { RecommendedRestockReportsQueryKey } from '../../queryKeys/RecommendedRestockReportsQueryKey';

export const useCreateRestock = () => {
  const url = useAutomatorApiAccountUrl(RESTOCKS_PATH);

  return useAuthorizedPost<Restock, CreateRestockData>(url, [
    InventoryItemsQueryKey(),
    RestocksQueryKey(RestockItemStatus.DELIVERED),
    RestocksQueryKey(RestockItemStatus.ORDERED),
    InventoryStocksQueryKey(),
    InventoryItemStockAdjustmentsQueryKey(),
    InventoryItemInsightQueryKey(),
    InventoryItemSuppliersQueryKey(),
    InventoryItemRestockItemsQueryKey(),
    RecommendedRestockReportsQueryKey(),
  ]);
};

export interface CreateRestockData {
  items: CreateRestockItemData[];
}

export interface CreateRestockItemData {
  inventory_item_id: number;
  supplier_id: number | null;
  quantity: number;
  unit_price_in_cents: number | null;
  unit_transport_cost_in_cents: number | null;
  total_cubic_meters: number | null;
  total_weight_in_kg: number | null;
}
