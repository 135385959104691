import { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import RecommendedRestockReport from '../../../../domain/automator/stock/RecommendedRestockReport';
import { RecommendedRestockReportTableRow } from './RecommendedRestockReportTableRow';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import { tokens } from '../../../../locales/translationTokens';

interface RecommendedRestockReportGroupTableRowProps {
  month: number; // Expecting month as a number (1-12)
  year: number;
  reports: RecommendedRestockReport[];
}

export const RecommendedRestockReportGroupTableRow = ({
  month,
  year,
  reports,
}: RecommendedRestockReportGroupTableRowProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // Create a Date object with the provided month and year
  const date = new Date(year, month - 1);

  // Format the date to display the month name in Dutch and the year
  const formattedDate = date.toLocaleString('nl-NL', { month: 'long', year: 'numeric' });

  return (
    <>
      <TableRow
        style={{ cursor: 'pointer' }}
        onClick={handleToggle}
      >
        <SlimTableCell>
          <Typography>{formattedDate}</Typography>
        </SlimTableCell>
        <SlimTableCell>
          <Typography>
            {reports.length}{' '}
            {t(tokens.automator.stock.recommended_restock_reports_table.inventory_items)}
          </Typography>
        </SlimTableCell>
        <SlimTableCell>
          <IconButton size="small">
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </SlimTableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={4}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse
            in={isExpanded}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t(tokens.automator.stock.recommended_restock_reports_table.inventory_item)}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {t(tokens.automator.stock.recommended_restock_reports_table.lead_time)}
                    </TableCell>
                    <TableCell>
                      {t(
                        tokens.automator.stock.recommended_restock_reports_table
                          .expected_depletion_date
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {reports.map((report) => (
                  <RecommendedRestockReportTableRow
                    key={report.id}
                    recommendedRestockReport={report}
                  />
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RecommendedRestockReportGroupTableRow;
