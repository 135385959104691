import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { useDetermineDepletionLevel } from './useDetermineDepletionLevel';

export const useDetermineDepletionLevelLabel = (
  leadTimeDays: number,
  daysTillDepletion?: number
) => {
  const { t } = useTranslation();

  const depletionLevel = useDetermineDepletionLevel(leadTimeDays, daysTillDepletion);

  switch (depletionLevel) {
    case 1:
      return t(tokens.automator.stock.depletion_level.critical);
    case 2:
      return t(tokens.automator.stock.depletion_level.very_low);
    case 3:
      return t(tokens.automator.stock.depletion_level.low);
    case 4:
      return t(tokens.automator.stock.depletion_level.okay);
    case 5:
      return t(tokens.automator.stock.depletion_level.good);
    case 6:
      return t(tokens.automator.stock.depletion_level.very_good);
    default:
      return 'N/A';
  }
};
