import TableRow from '@mui/material/TableRow';
import Return from '../../../../domain/automator/returns/Return';
import { ReturnItemCard } from './ReturnItemCard';
import { ReturnItemStatus } from '../../../../domain/automator/returns/ReturnItemStatus';
import { ReturnReasonStack } from './ReturnReasonStack';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import DaysSinceRegistrationText from './DaysUntilExpirationText';
import { ReturnItemFilter } from '../../../../domain/automator/returns/ReturnItemFilter';
import ReturnItem from '../../../../domain/automator/returns/ReturnItem';
import { ScannedIconChip } from '../../returnScans/components/ScannedIcon';
import Stack from '@mui/material/Stack';
import ProcessReturnItemPopoverButton from './ProcessReturnItemPopoverButton';
import { Box } from '@mui/system';

interface ReturnTableRowProps {
  return_: Return;
  currentTab: ReturnItemFilter;
  showDaysRegistered: boolean;
}

export const ReturnTableRow = ({
  return_,
  currentTab,
  showDaysRegistered,
}: ReturnTableRowProps) => {
  const filterItems = (returnItems: ReturnItem[], filter: ReturnItemFilter) => {
    switch (filter) {
      case ReturnItemFilter.OVERVIEW:
        return returnItems.filter((returnItem) => {
          return (
            (returnItem.status == ReturnItemStatus.REGISTERED && !returnItem.isHandled) ||
            (returnItem.status == ReturnItemStatus.ON_HOLD && !returnItem.isHandled) ||
            (returnItem.status == ReturnItemStatus.REPAIR && !returnItem.isScanned) ||
            (returnItem.status == ReturnItemStatus.EXCHANGE && !returnItem.isScanned)
          );
        });
      case ReturnItemFilter.ON_HOLD:
        return returnItems.filter((returnItem) => returnItem.status == ReturnItemStatus.ON_HOLD);
      case ReturnItemFilter.REPAIR:
        return returnItems.filter((returnItem) => returnItem.status == ReturnItemStatus.REPAIR);
      case ReturnItemFilter.EXCHANGE:
        return returnItems.filter((returnItem) => returnItem.status == ReturnItemStatus.EXCHANGE);
      case ReturnItemFilter.CUSTOMER_KEEPS_PRODUCT_PAID:
        return returnItems.filter(
          (returnItem) => returnItem.status == ReturnItemStatus.CUSTOMER_KEEPS_PRODUCT_PAID
        );
      case ReturnItemFilter.HANDLED:
        return returnItems.filter((returnItem) => returnItem.isHandled);
      case ReturnItemFilter.ALL:
        return returnItems;
    }
  };

  const returnItems = return_.returnItems;

  return (
    <>
      {filterItems(returnItems, currentTab).map((returnItem, index) => {
        const hasBorderBottom = returnItems.length === 1 || index === returnItems.length - 1;

        return (
          <TableRow key={returnItem.id}>
            <SlimTableCell width={10}>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                {returnItem.isScanned ? <ScannedIconChip /> : <></>}
                {returnItem.rmaId}
              </Stack>
            </SlimTableCell>

            <SlimTableCell
              width={1}
              hasBorderBottom={hasBorderBottom}
            >
              <ReturnItemCard
                key={returnItem.id}
                quantity={returnItem.quantityUnprocessed}
                returnItem={returnItem}
                return_={return_}
                showTransporterInfo={true}
              />
            </SlimTableCell>

            <SlimTableCell
              align={'left'}
              hasBorderBottom={hasBorderBottom}
            >
              <ReturnReasonStack returnItem={returnItem} />
            </SlimTableCell>

            {showDaysRegistered && (
              <SlimTableCell
                hasBorderBottom={hasBorderBottom}
                align="right"
              >
                <DaysSinceRegistrationText daysSinceRegistration={return_.daysSinceRegistration} />
              </SlimTableCell>
            )}

            <SlimTableCell
              hasBorderBottom={hasBorderBottom}
              align="right"
            >
              <Box>
                {currentTab != ReturnItemFilter.HANDLED && (
                  <ProcessReturnItemPopoverButton returnItem={returnItem} />
                )}
              </Box>
            </SlimTableCell>
          </TableRow>
        );
      })}
    </>
  );
};
