import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import StockAdjustment from '../../../../domain/automator/stock/StockAdjustment';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import { StockAdjustmentType } from '../../../../domain/automator/stock/StockAdjustmentType';
import SalesStockAdjustmentTableRow from './SalesStockAdjustmentTableRow';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';

interface StockAdjustmentTableRowProps {
  inventoryItem: InventoryItem;
  stockAdjustments: StockAdjustment[];
}

export const StockAdjustmentTableRow = ({
  inventoryItem,
  stockAdjustments,
}: StockAdjustmentTableRowProps) => {
  const totalQuantity = stockAdjustments.reduce(
    (sum, adjustment) => sum + adjustment.stockValues.quantity,
    0
  );

  if (stockAdjustments[0].type === StockAdjustmentType.SALES) {
    return (
      <SalesStockAdjustmentTableRow
        inventoryItem={inventoryItem}
        date={stockAdjustments[0].date}
        quantity={totalQuantity}
      />
    );
  } else {
    return (
      <TableRow>
        <TableCell>
          <Typography>
            {getShortendAlphabeticFormatFromDate(new Date(stockAdjustments[0].date), false, true)}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography>{totalQuantity}</Typography>
        </TableCell>
      </TableRow>
    );
  }
};
