import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { SUPPLY_CHAIN_PHASES } from '../automatorApiPaths';
import { SupplyChainPhasesQueryKey } from '../../queryKeys/SupplyChainPhasesQueryKey';
import SupplyChainPhase from '../../../domain/automator/stock/SupplyChainPhase';

export const useFetchSupplyChainPhases = () => {
  const url = useAutomatorApiAccountUrl(SUPPLY_CHAIN_PHASES);
  return useFetchData<SupplyChainPhase[]>(url, SupplyChainPhasesQueryKey());
};
