import { useState } from 'react';
import { useUpdateSupplyChainPhase } from '../../../../api/automator/stock/useUpdateSupplyChainPhase';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import TextField from '@mui/material/TextField';
import ActionButton from '../../../../components/ActionButton';
import Stack from '@mui/material/Stack';
import SupplyChainPhase from '../../../../domain/automator/stock/SupplyChainPhase';

interface UpdateSupplyChainPhaseFormProps {
  supplyChainPhase: SupplyChainPhase;
  onClick?: () => void;
}

export const UpdateSupplyChainPhaseForm = ({
  supplyChainPhase,
  onClick,
}: UpdateSupplyChainPhaseFormProps) => {
  const [name, setName] = useState<string>(supplyChainPhase.name);
  const [duration, setDuration] = useState<number>(supplyChainPhase.durationInDays);

  const { mutate: updateSupplyChainPhase, isLoading } = useUpdateSupplyChainPhase(
    supplyChainPhase.id
  );

  const { t } = useTranslation();

  const doUpdate = (name: string, duration: number) => {
    updateSupplyChainPhase(
      {
        name: name,
        duration_in_days: duration,
      },
      {
        onSuccess: async () => {
          toast.success(
            t(tokens.automator.stock.supply_chain_phase_dialog.supply_chain_phase_updated)
          );
          onClick?.();
        },
      }
    );
  };

  return (
    <Stack
      direction="column"
      gap={2}
    >
      <TextField
        label={t(tokens.automator.stock.supply_chain_phase_dialog.name)}
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />

      <TextField
        label={t(tokens.automator.stock.supply_chain_phase_dialog.duration_in_days)}
        variant="outlined"
        type="number"
        value={duration}
        onChange={(e) => setDuration(Number(e.target.value))}
        required
      />

      <TextField
        label={t(tokens.automator.stock.supply_chain_phase_dialog.type)}
        variant="outlined"
        value={supplyChainPhase.type}
        disabled
      />

      <ActionButton
        onClick={() => doUpdate(name, duration)}
        isLoading={isLoading}
        label={tokens.automator.stock.supply_chain_phase_dialog.update_supply_chain_phase}
      />
    </Stack>
  );
};
