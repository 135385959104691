import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import * as React from 'react';
import { useState } from 'react';
import { SalesVariantInfo } from './SalesVariantInfo';
import RecommendedRestockReport from '../../../../domain/automator/stock/RecommendedRestockReport';
import { getAlphabeticFormatFromDate } from '../../../../helpers/getAlphabeticFormatFromDate';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { StockPeriodDropdown, StockPeriodOption } from './StockPeriodDropdown';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { convertCentsToEuros } from '../../../pd/helpers/convertCentsToEuros';
import { Box } from '@mui/system';
import roundDownTo1Decimal from '../../../pd/helpers/roundDownTo1Decimal';

interface RecommendedRestockReportTableRowProps {
  recommendedRestockReport: RecommendedRestockReport;
}

export const RecommendedRestockReportTableRow = ({
  recommendedRestockReport,
}: RecommendedRestockReportTableRowProps) => {
  const { t } = useTranslation();

  const [stockPeriodOption, setStockPeriodOption] = useState<StockPeriodOption | null>(null);

  const units =
    recommendedRestockReport.averageSales30d && stockPeriodOption
      ? recommendedRestockReport.averageSales30d * (stockPeriodOption?.days || 0)
      : null;

  const totalCost =
    units && recommendedRestockReport.unitPriceInCents
      ? units * recommendedRestockReport.unitPriceInCents
      : null;

  const totalVolume =
    units && recommendedRestockReport.totalCubicMeters
      ? units * recommendedRestockReport.totalCubicMeters
      : null;

  return (
    <>
      <TableRow style={{ cursor: 'pointer' }}>
        <SlimTableCell>
          <SalesVariantInfo
            salesVariant={recommendedRestockReport.salesVariants[0]}
            hasSiblingVariant={recommendedRestockReport.salesVariants.length > 1}
            date={recommendedRestockReport.recommendedRestockDate}
          />
        </SlimTableCell>

        <SlimTableCell>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
          >
            <ArrowCircleRightIcon color="warning" />

            <StockPeriodDropdown
              selected={stockPeriodOption}
              onSelect={setStockPeriodOption}
            />
          </Stack>

          <Stack
            direction="row"
            gap={2}
          >
            <Typography width={100}>
              {t(tokens.automator.stock.restock_dialog.unit_price)}
            </Typography>

            {recommendedRestockReport.unitPriceInCents && (
              <Typography>
                €{convertCentsToEuros(recommendedRestockReport.unitPriceInCents)}
              </Typography>
            )}
          </Stack>

          <Stack
            direction="row"
            gap={2}
          >
            <Typography width={100}>{t(tokens.automator.stock.restock_dialog.volume)}</Typography>

            {recommendedRestockReport.totalCubicMeters && (
              <Typography>
                {convertCentsToEuros(recommendedRestockReport.totalCubicMeters)} m3
              </Typography>
            )}
          </Stack>
        </SlimTableCell>

        <SlimTableCell>
          <Box>
            {units && (
              <Stack
                direction="row"
                gap={0.5}
              >
                <Typography>{roundDownTo1Decimal(units)}</Typography>

                <Typography>
                  {t(tokens.automator.stock.recommended_restock_reports_table.units)}
                </Typography>
              </Stack>
            )}
          </Box>
        </SlimTableCell>

        <SlimTableCell>
          <Box>{totalCost && <Typography>€{convertCentsToEuros(totalCost)}</Typography>}</Box>
        </SlimTableCell>

        <SlimTableCell>
          <Box>{totalVolume && <Typography>{roundDownTo1Decimal(totalVolume)} m3</Typography>}</Box>
        </SlimTableCell>

        <SlimTableCell>
          <Typography>
            {recommendedRestockReport.leadTime.transportDurationInDays +
              recommendedRestockReport.leadTime.transportDurationInDays}
            {' ' + t(tokens.automator.stock.settings.supply_chain_phases.days)}
          </Typography>
        </SlimTableCell>

        <SlimTableCell>
          <Typography>
            {getAlphabeticFormatFromDate(
              new Date(recommendedRestockReport.expectedDepletionDate!),
              false,
              true
            )}
          </Typography>
        </SlimTableCell>
      </TableRow>
    </>
  );
};
