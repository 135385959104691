import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import { DepletionPeriodChip } from './DepletionPeriodChip';
import { Collapse } from '@mui/material';
import { InventoryItemDetails } from './InventoryItemDetails';
import { StockAmount } from './StockAmount';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import * as React from 'react';
import { memo } from 'react';
import { SalesVariantInfo } from './SalesVariantInfo';
import { StockAverageSalesToggleButtons } from './StockAverageSalesToggleButton';
import Stack from '@mui/material/Stack';
import { StockSalesTrendIcon } from './StockSalesTrendIconProps';
import { OrderedStockInfo } from './OrderedStockInfo';
import { RestockSignalIndicator } from './RestockSignalIndicator';
import { useInventoryItemDaysAverage } from '../../../../store/inventoryItemDaysAverage/useInventoryItemDaysAverage';

interface InventoryItemTableRowProps {
  inventoryItem: InventoryItem;
  showDetails: boolean;
  setShowDetailsInventoryItemId: (inventoryItemId: number) => void;
}

export const InventoryItemTableRow = memo(
  ({ inventoryItem, showDetails, setShowDetailsInventoryItemId }: InventoryItemTableRowProps) => {
    const { days, updateItemDays } = useInventoryItemDaysAverage(inventoryItem.id);

    const daysTillDepletion =
      days === 7
        ? inventoryItem.daysTillsDepletion7d
        : days === 14
        ? inventoryItem.daysTillsDepletion14d
        : inventoryItem.daysTillsDepletion30d;

    const updateShowDetailsInventoryItemId = () => {
      setShowDetailsInventoryItemId(inventoryItem.id);
    };

    return (
      <>
        <TableRow style={{ cursor: 'pointer' }}>
          <SlimTableCell onClick={updateShowDetailsInventoryItemId}>
            <SalesVariantInfo
              salesVariant={inventoryItem.salesVariants[0]}
              hasSiblingVariant={inventoryItem.salesVariants.length > 1}
              date={null}
            />
          </SlimTableCell>
          <SlimTableCell onClick={updateShowDetailsInventoryItemId}>
            <Stack
              direction="column"
              gap={1}
              alignItems="left"
            >
              <StockAmount amount={inventoryItem.currentStock} />
              {inventoryItem.orderedStock != null && (
                <OrderedStockInfo inventoryItem={inventoryItem} />
              )}
            </Stack>
          </SlimTableCell>
          <SlimTableCell onClick={updateShowDetailsInventoryItemId}>
            {daysTillDepletion != null ? (
              <DepletionPeriodChip
                leadTime={inventoryItem.leadTime}
                daysTillDepletion={daysTillDepletion}
              />
            ) : (
              <Typography></Typography>
            )}
          </SlimTableCell>
          <SlimTableCell>
            <RestockSignalIndicator
              daysTillDepletion={daysTillDepletion}
              leadTime={inventoryItem.leadTime}
              restockSignal={inventoryItem.restockSignal}
            />
          </SlimTableCell>

          <SlimTableCell>
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
            >
              <StockAverageSalesToggleButtons
                selectedDays={days}
                onChange={(newDays) => {
                  updateItemDays(newDays);
                }}
                averageSalesPerDay7d={inventoryItem.averageSalesPerDay7d}
                averageSalesPerDay14d={inventoryItem.averageSalesPerDay14d}
                averageSalesPerDay30d={inventoryItem.averageSalesPerDay30d}
              />
              <StockSalesTrendIcon
                averageSalesPerDay7d={inventoryItem.averageSalesPerDay7d}
                averageSalesPerDay30d={inventoryItem.averageSalesPerDay30d}
              />
            </Stack>
          </SlimTableCell>
        </TableRow>
        <TableRow key={`collapse-${inventoryItem.id}`}>
          <SlimTableCell
            height={-100}
            colSpan={10}
            style={{ padding: 0, margin: 0 }}
          >
            <Collapse
              in={showDetails}
              unmountOnExit
            >
              <InventoryItemDetails inventoryItem={inventoryItem} />
            </Collapse>
          </SlimTableCell>
        </TableRow>
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.showDetails === nextProps.showDetails &&
      prevProps.inventoryItem === nextProps.inventoryItem
    );
  }
);

InventoryItemTableRow.displayName = 'ProductStockSummaryTableRow';
