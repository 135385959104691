import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface QuantityDropdownProps {
  maxQuantity: number;
  onSelect: (quantity: number) => void;
  quantity: number;
}

export const QuantityDropdown = ({ maxQuantity, onSelect, quantity }: QuantityDropdownProps) => {
  const { t } = useTranslation();

  function generateList(maxQuantity: number): number[] {
    const list = [];
    for (let i = 1; i <= maxQuantity; i++) {
      list.push(i);
    }
    return list;
  }

  const options = generateList(maxQuantity).map((quantity) => ({
    key: quantity,
    value: quantity,
  }));

  return (
    <TextField
      style={{ minWidth: 90 }}
      label={t(tokens.automator.returns.quantity)}
      SelectProps={{ native: true }}
      select
      value={quantity}
      onChange={(e) => onSelect(parseInt(e.target.value))}
    >
      {options.map((option) => (
        <option
          key={option.key}
          value={option.key}
        >
          {option.value}
        </option>
      ))}
    </TextField>
  );
};
