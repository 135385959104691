import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import RestockItem from '../../../domain/automator/stock/RestockItem';
import { InventoryItemRestockItemsQueryKey } from '../../queryKeys/InventoryItemRestockItemsQueryKey';

export const useFetchInventoryItemRestockItems = (inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(INVENTORY_ITEMS + `/${inventoryItemId}/restock-items`);
  return useFetchData<RestockItem[]>(url, InventoryItemRestockItemsQueryKey(inventoryItemId));
};
