import { deepOrange, green, grey, red } from '@mui/material/colors';
import { useDetermineDepletionLevel } from './useDetermineDepletionLevel';

export const useDetermineDepletionLevelColor = (
  leadTimeDays: number,
  daysTillDepletion?: number
) => {
  const depletionLevel = useDetermineDepletionLevel(leadTimeDays, daysTillDepletion);

  switch (depletionLevel) {
    case 1:
      return red[900];
    case 2:
      return red[600];
    case 3:
      return deepOrange[300];
    case 4:
      return green[300];
    case 5:
      return green[700];
    case 6:
      return grey[600];
    default:
      return undefined;
  }
};
