import * as React from 'react';
import Chip from '@mui/material/Chip';
import { useDetermineDepletionLevelLabel } from '../services/useDetermineDepletionLevelLabel';
import { useDetermineDepletionLevelColor } from '../services/useDetermineDepletionLevelColor';

interface DepletionLevelChipProps {
  leadTimeDays: number;
  daysTillDepletion?: number;
}

export const DepletionLevelChip = ({
  leadTimeDays,
  daysTillDepletion,
}: DepletionLevelChipProps) => {
  return (
    <Chip
      style={{ color: useDetermineDepletionLevelColor(leadTimeDays, daysTillDepletion) }}
      label={useDetermineDepletionLevelLabel(leadTimeDays, daysTillDepletion)}
      variant="outlined"
    />
  );
};
