import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import {
  InventoryItemsSort,
  useFetchInventoryItems,
} from '../../../../api/automator/stock/useFetchInventoryItems';
import Table from '@mui/material/Table';
import { ProductStockSummaryDepletionPeriodTableRow } from './ProductStockSummaryDepletionPeriodTableRow';
import { Fragment, useState } from 'react';
import { InventoryItemTableRow } from './InventoryItemTableRow';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import { SortDirection } from '../../../../api/page/SortDirection';

export const ProductStockSummariesInsightListTable = () => {
  const { t } = useTranslation();
  const [selectedDays, setSelectedDays] = useState<number | -1 | null>(null);
  const { data: paginatedProductStockSummaries, isLoading } = useFetchInventoryItems(
    0,
    1000000,
    InventoryItemsSort.TITLE,
    SortDirection.ASC
  );

  if (isLoading || !paginatedProductStockSummaries) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (paginatedProductStockSummaries.content.inventoryItems.length === 0) {
    return (
      <TableEmpty message={t(tokens.automator.stock.product_stock_summaries_table.no_items)} />
    );
  }

  const items = paginatedProductStockSummaries.content.inventoryItems;

  const getDepletionDays = (item: InventoryItem, depletionDaysValues: number[]) => {
    if (item.currentStock === 0 && item.daysTillsDepletion30d !== 0) {
      return depletionDaysValues[0];
    } else if (item.daysTillsDepletion30d === null || item.daysTillsDepletion30d === 0) {
      return -1;
    }

    for (let i = 1; i < depletionDaysValues.length; i++) {
      if (item.daysTillsDepletion30d < depletionDaysValues[i]) {
        return depletionDaysValues[i - 1];
      }
    }

    return depletionDaysValues[depletionDaysValues.length - 1];
  };

  const depletionDaysValues = [0, 1, 15, 29, 43, 57, 85, 113, 141, 169, 365];

  // Define the type for groupedItems explicitly.
  type GroupedItemsType = { [key: number]: InventoryItem[] };

  const groupedItems = items.reduce((groups: GroupedItemsType, item) => {
    const depletionDays = getDepletionDays(item, depletionDaysValues);
    if (!groups[depletionDays]) {
      groups[depletionDays] = [];
    }
    groups[depletionDays].push(item);
    return groups;
  }, {} as GroupedItemsType);

  return (
    <Table>
      {depletionDaysValues.map((depletionDays) => {
        const groupItems = groupedItems[depletionDays] || [];
        return (
          <Fragment key={depletionDays}>
            <ProductStockSummaryDepletionPeriodTableRow
              amount={groupItems.length}
              depletionDays={depletionDays === -1 ? undefined : depletionDays}
              onClick={() => setSelectedDays(depletionDays)}
            />
            {selectedDays === depletionDays &&
              groupItems.map((item) => (
                <InventoryItemTableRow
                  key={item.id}
                  inventoryItem={item}
                  showDetails={false}
                  setShowDetailsInventoryItemId={() => null}
                />
              ))}
          </Fragment>
        );
      })}
    </Table>
  );
};
