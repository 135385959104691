import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { tokens } from '../../../../locales/translationTokens';
import { EditRestockItem, RestockItemForm, RestockItemMetricOption } from './RestockItemForm';
import ActionButton from '../../../../components/ActionButton';
import { useFetchReducedInventoryItems } from '../../../../api/automator/stock/useFetchReducedInventoryItems';
import Typography from '@mui/material/Typography';
import ReducedInventoryItem from '../../../../domain/automator/stock/ReducedInventoryItem';
import { RestockItemStatus } from '../../../../domain/automator/stock/RestockItemStatus';
import Divider from '@mui/material/Divider';

interface RestockFormProps {
  onSave?: (item: EditRestockItem[]) => void;
  supplierId: number | null;
  inventoryItem?: ReducedInventoryItem;
}

export const RestockForm = ({ onSave, supplierId, inventoryItem }: RestockFormProps) => {
  const { t } = useTranslation();

  const { data: inventoryItems, isLoading } = useFetchReducedInventoryItems();

  const createNewRestockItem = () => {
    return {
      inventoryItem: { id: 0, supplierIds: [], salesVariants: [] },
      supplierId: supplierId,
      quantity: 1,
      unitPriceInCents: 0,
      unitTransportCostInCents: 0,
      totalCubicMeters: 0,
      totalWeightInKg: 0,
      status: RestockItemStatus.DELIVERED,
      daysUntilDelivery: 0,
      metricOption: RestockItemMetricOption.BY_UNIT,
    };
  };

  const initialRestockItems = () => {
    return [
      {
        inventoryItem: inventoryItem || { id: 0, supplierIds: [], salesVariants: [] },
        supplierId: supplierId,
        quantity: 1,
        unitPriceInCents: null,
        unitTransportCostInCents: null,
        totalCubicMeters: null,
        totalWeightInKg: null,
        status: RestockItemStatus.DELIVERED,
        daysUntilDelivery: 0,
        metricOption: RestockItemMetricOption.BY_UNIT,
      },
    ];
  };

  const [restockItems, setRestockItems] = useState<EditRestockItem[]>(initialRestockItems());

  const onAddNew = () => {
    const newItems = [...restockItems];
    newItems[newItems.length] = createNewRestockItem();
    setRestockItems(newItems);
  };

  const onUpdateItem = (index: number, item: EditRestockItem) => {
    const newItems = [...restockItems];
    newItems[index] = item;
    setRestockItems(newItems);
  };

  const onRemoveItem = (index: number) => {
    setRestockItems(restockItems.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (inventoryItems && supplierId != null) {
      const supplierInventoryItems = inventoryItems!.inventoryItems.filter((item) =>
        item.supplierIds.find((id) => id == supplierId)
      );

      const restockItems = supplierInventoryItems.map((item) => {
        return {
          inventoryItem: item,
          supplierId: supplierId,
          quantity: 0,
          unitPriceInCents: null,
          unitTransportCostInCents: null,
          totalCubicMeters: null,
          totalWeightInKg: null,
          status: RestockItemStatus.DELIVERED,
          daysUntilDelivery: 0,
          metricOption: RestockItemMetricOption.BY_UNIT,
        };
      });

      setRestockItems(restockItems);
    }
  }, [inventoryItems]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        gap={2}
        divider={
          <Divider
            orientation="horizontal"
            flexItem
          />
        }
      >
        {restockItems.map((item, index) => {
          return (
            <RestockItemForm
              key={index}
              onUpdate={(item) => onUpdateItem(index, item)}
              onRemove={() => onRemoveItem(index)}
              item={item}
              supplierId={supplierId}
              canUpdateQuantity={true}
            />
          );
        })}
      </Stack>

      <ActionButton
        icon={<PlusIcon />}
        label={tokens.automator.stock.restock_dialog.add_item}
        onClick={() => onAddNew()}
        variant="outlined"
      />

      <ActionButton
        isDisabled={
          restockItems.length == 0 ||
          restockItems.some((item) => item.inventoryItem.id == 0 || item.inventoryItem.id == null)
        }
        color="primary"
        label={t(tokens.automator.stock.restock_dialog.save)}
        onClick={() => onSave && onSave(restockItems)}
      />
    </Stack>
  );
};
