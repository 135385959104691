import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import { useFetchReturnsByInventoryItemId } from '../../../../api/automator/returns/useFetchReturnsByInventoryItemId';
import { ReturnListTable } from '../../returns/components/ReturnListTable';
import { ReturnItemFilter } from '../../../../domain/automator/returns/ReturnItemFilter';

interface InventoryItemReturnsListTableProps {
  inventoryItemId: number;
}

export const InventoryItemReturnsListTable = ({
  inventoryItemId,
}: InventoryItemReturnsListTableProps) => {
  const { data: returns, isLoading } = useFetchReturnsByInventoryItemId(inventoryItemId);

  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (returns!.length == 0) {
    return (
      <TableEmpty message={t(tokens.automator.stock.product_stock_summaries_table.no_items)} />
    );
  }

  return (
    <ReturnListTable
      currentTab={ReturnItemFilter.ALL}
      returns={returns && returns}
      isLoading={isLoading}
    />
  );
};
