// ReturnItemButtons.tsx
import ButtonGroup from '@mui/material/ButtonGroup';
import { ReturnItemFilter } from '../../../../domain/automator/returns/ReturnItemFilter';
import { ReturnItemStatus } from '../../../../domain/automator/returns/ReturnItemStatus';
import OnHoldActionButton from './OnHoldActionButton';
import AwaitingRepairActionButton from './AwaitingRepairActionButton';
import AwaitingExchangeActionButton from './AwaitingExchangeActionButton';
import CustomerKeepsProductPaidActionButton from './CustomerKeepsProductPaidActionButton';

interface ReturnItemButtonsProps {
  returnItem: {
    id: number;
    status: ReturnItemStatus;
  };
  currentTab: ReturnItemFilter;
  onSuccess?: () => void;
}

const ReturnItemButtons = ({ returnItem, currentTab, onSuccess }: ReturnItemButtonsProps) => {
  if (currentTab === ReturnItemFilter.HANDLED) {
    return null;
  }

  return (
    <ButtonGroup
      variant="outlined"
      color="info"
      size="small"
      sx={{ height: 'auto' }}
    >
      <OnHoldActionButton
        returnItemId={returnItem.id}
        currentStatus={returnItem.status}
        onSuccess={onSuccess}
      />
      <AwaitingRepairActionButton
        returnItemId={returnItem.id}
        currentStatus={returnItem.status}
        onSuccess={onSuccess}
      />
      <AwaitingExchangeActionButton
        returnItemId={returnItem.id}
        currentStatus={returnItem.status}
        onSuccess={onSuccess}
      />
      <CustomerKeepsProductPaidActionButton
        returnItemId={returnItem.id}
        currentStatus={returnItem.status}
        onSuccess={onSuccess}
      />
    </ButtonGroup>
  );
};

export default ReturnItemButtons;
