import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { InventoryStockType } from '../../../../domain/automator/stock/InventoryStockType';

interface InventoryStockTypeChipProps {
  type: InventoryStockType;
}

export const InventoryStockTypeChip = ({ type }: InventoryStockTypeChipProps) => {
  const { t } = useTranslation();

  switch (type) {
    case InventoryStockType.RESTOCK:
      return (
        <Chip
          label={t(tokens.automator.stock.inventory_stock_type.restock)}
          color={'primary'}
          variant="outlined"
          size="small"
        />
      );
    case InventoryStockType.STOCKTAKE:
      return (
        <Chip
          label={t(tokens.automator.stock.inventory_stock_type.stocktake)}
          color={'secondary'}
          variant="outlined"
          size="small"
        />
      );
  }
};
