import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import InventoryStock from '../../../domain/automator/stock/InventoryStock';
import { InventoryStocksQueryKey } from '../../queryKeys/InventoryStocksQueryKey';

export const useFetchInventoryStocks = (inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(INVENTORY_ITEMS + `/${inventoryItemId}/stocks`);
  return useFetchData<InventoryStock[]>(url, InventoryStocksQueryKey(inventoryItemId));
};
