import Stack from '@mui/material/Stack';
import SalesVariant from '../../../domain/automator/stock/SalesVariant';
import { useState } from 'react';
import { UpdateOffersForm } from '../orders/components/UpdateOffersForm';
import { tokens } from '../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

type UpdateInventoryItemOfferListingFormProps = {
  salesVariants: SalesVariant[];
  onClick: () => void;
};

export const UpdateInventoryItemOfferListingForm = ({
  salesVariants,
}: UpdateInventoryItemOfferListingFormProps) => {
  const [salesVariantId, setSalesVariantId] = useState(salesVariants[0].id);

  const { t } = useTranslation();

  const options = salesVariants.map((salesVariant) => ({
    key: salesVariant.id,
    value: salesVariant.title,
  }));

  return (
    <Stack
      direction="column"
      gap={4}
    >
      {options.length > 1 && (
        <TextField
          label={t(tokens.automator.orders.dialogs.update_offer.update_offers)}
          select
          value={salesVariantId}
          SelectProps={{ native: true }}
          onChange={(e) => setSalesVariantId(Number(e.target.value))}
        >
          {options.map((option) => (
            <option
              key={option.key}
              value={option.key}
            >
              {option.value}
            </option>
          ))}
        </TextField>
      )}

      {salesVariantId && (
        <UpdateOffersForm
          productId={
            salesVariants.find((salesVariant) => salesVariant.id == salesVariantId)!.productId
          }
        />
      )}
    </Stack>
  );
};
