import { STOCK_SETTINGS } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { StockSettingsQueryKey } from '../../queryKeys/StockSettingsQueryKey';
import StockSettings from '../../../domain/automator/stock/StockSettings';

export const useUpdateStockSettings = (stockSettingsId: number) => {
  const url = useAutomatorApiAccountUrl(STOCK_SETTINGS + `/${stockSettingsId}`);
  return useAuthorizedPost<StockSettings, UpdateStockSettingsData>(url, [StockSettingsQueryKey()]);
};

export interface UpdateStockSettingsData {
  default_manufacturing_supply_chain_phase_id: number | null;
  default_transport_supply_chain_phase_id: number | null;
}
