import { Autocomplete, TextField } from '@mui/material';
import { tokens } from '../../../../locales/translationTokens';
import { truncateString } from '../../../../helpers/truncateString';
import { ProductThumbnail } from '../../orders/components/ProductThumbnail';
import Stack from '@mui/material/Stack';
import { useFetchReducedInventoryItems } from '../../../../api/automator/stock/useFetchReducedInventoryItems';
import { useTranslation } from 'react-i18next';
import ReducedInventoryItem from '../../../../domain/automator/stock/ReducedInventoryItem';

interface InventoryItemMultiSelectProps {
  inventoryItemIds: number[];
  onSelect: (inventoryItems: ReducedInventoryItem[]) => void;
  multiple?: boolean;
  supplierId: number | null;
  isDisabled?: boolean;
}

interface OptionType {
  value: number;
  item: ReducedInventoryItem;
  label: string;
  query: string;
  firstLetter: string;
  thumbnailUrl?: string;
}

export const InventoryItemMultiSelect = ({
  inventoryItemIds,
  onSelect,
  supplierId,
  multiple = true,
  isDisabled = false,
}: InventoryItemMultiSelectProps) => {
  const { data: inventoryItems, isLoading } = useFetchReducedInventoryItems();

  const options: OptionType[] = isLoading
    ? []
    : inventoryItems!.inventoryItems
        .filter((inventoryItem) => {
          if (supplierId) {
            return inventoryItem.supplierIds.find((id) => id == supplierId);
          }
          return true;
        })
        .map((inventoryItem) => {
          if (inventoryItem.salesVariants.length === 0) {
            console.error(`Inventory item ${inventoryItem.id} has no sales variants`);
          }

          return {
            value: inventoryItem.id,
            item: inventoryItem,
            label: truncateString(inventoryItem.salesVariants[0].title, 60),
            query:
              inventoryItem.salesVariants.map((salesVariant) => salesVariant.title).join(',') +
              '_' +
              inventoryItem.salesVariants
                .map((salesVariant) => salesVariant.internalReference)
                .join(','),
            firstLetter: inventoryItem.salesVariants[0].title[0].toUpperCase(),
            thumbnailUrl: inventoryItem.salesVariants[0].thumbnailUrl,
          };
        });

  const handleChange = (newValue: OptionType | OptionType[] | null) => {
    if (multiple) {
      onSelect((newValue as OptionType[]).map((option) => option.item));
    } else {
      onSelect(newValue ? [(newValue as OptionType).item] : []);
    }
  };

  const { t } = useTranslation();

  return (
    <Autocomplete
      disabled={isLoading || isDisabled}
      multiple={multiple}
      options={options}
      getOptionLabel={(option: OptionType) => option.query} // this is what is searched on
      groupBy={(option: OptionType) => option.firstLetter}
      value={
        multiple
          ? options.filter((option) => inventoryItemIds.includes(option.value))
          : options.find((option) => option.value === inventoryItemIds[0]) || null
      }
      onChange={(_, value) => handleChange(value)}
      openOnFocus
      disableCloseOnSelect={multiple}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t(tokens.automator.stock.inventory_item)}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && (
            <input
              style={{ marginRight: '20px', marginLeft: '-10px' }}
              type="checkbox"
              checked={selected}
              readOnly
            />
          )}
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
          >
            <ProductThumbnail
              thumbnailUrl={option.thumbnailUrl}
              height={40}
            />
            {option.label}
          </Stack>
        </li>
      )}
    />
  );
};
