import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { StockUnitPrice } from './StockUnitPrice';
import { StockUnitShippingCost } from './StockUnitShippingCost';
import { StockCubicMeters } from './StockCubicMeters';
import { StockWeight } from './StockWeight';
import Stack from '@mui/material/Stack';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import { SubStockAmount } from '../SubStockAmount';
import { InventoryStockTypeChip } from './InventoryStockTypeChip';
import RestockItem from '../../../../domain/automator/stock/RestockItem';
import { InventoryStockType } from '../../../../domain/automator/stock/InventoryStockType';
import { getAlphabeticFormatFromDate } from '../../../../helpers/getAlphabeticFormatFromDate';
import { RestockItemDeliveredActionButton } from './RestockItemDeliveredActionButton';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import ActionButton from '../../../../components/ActionButton';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';

interface RestockItemTableRowProps {
  restockItem: RestockItem;
}

export const RestockItemTableRow = ({ restockItem }: RestockItemTableRowProps) => {
  const openUpdateRestockItemDialog = useOpenDialog(DialogId.UPDATE_RESTOCK_ITEM);

  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>
        <InventoryStockTypeChip type={InventoryStockType.RESTOCK} />
      </TableCell>

      <TableCell>
        <Typography>
          {getShortendAlphabeticFormatFromDate(new Date(restockItem.createdDate), false, true)}
        </Typography>
      </TableCell>

      <TableCell>
        <SubStockAmount amount={restockItem.stockValues.quantity} />
      </TableCell>

      <TableCell>
        <StockUnitPrice amount={restockItem.stockValues.unitPriceInCents} />
      </TableCell>

      <TableCell>
        <StockUnitShippingCost amount={restockItem.stockValues.unitTransportCostInCents} />
      </TableCell>

      <TableCell>
        <StockCubicMeters amount={restockItem.stockValues.totalCubicMeters} />
      </TableCell>

      <TableCell>
        <StockWeight amount={restockItem.stockValues.totalWeightInKg} />
      </TableCell>

      <TableCell>
        {restockItem.expectedDeliveryDate ? (
          <Stack
            direction="row"
            gap={0.5}
            alignItems="center"
          >
            <Typography>
              {getAlphabeticFormatFromDate(new Date(restockItem.expectedDeliveryDate), false, true)}
            </Typography>

            {/*{inventoryStock.status == InventoryStockStatus.ORDERED && (*/}
            {/*  <ActionIcon*/}
            {/*    color="primary"*/}
            {/*    onClick={() => editRestock({ restockId: inventoryStock.restockId! })}*/}
            {/*    icon={<EventAvailableIcon />}*/}
            {/*    tooltip={t(tokens.automator.stock.edit_restock_dialog.edit_restock)}*/}
            {/*  />*/}
            {/*)}*/}
          </Stack>
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell align="right">
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          {restockItem.inventoryStockId == null && (
            <RestockItemDeliveredActionButton
              restockItemId={restockItem.id}
              inventoryItemId={restockItem.stockValues.inventoryItemId}
            />
          )}

          <ActionButton
            onClick={() =>
              openUpdateRestockItemDialog({
                restockItem: restockItem,
              })
            }
            label={t(tokens.automator.stock.update_restock_item.update_restock_item)}
            variant={'outlined'}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
