import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import Stack from '@mui/material/Stack';
import ActionButton from '../../../../components/ActionButton';
import ReturnItem from '../../../../domain/automator/returns/ReturnItem';
import PopoverButton from '../../shared/PopoverButton';
import OnHoldActionButton from './OnHoldActionButton';
import AwaitingRepairActionButton from './AwaitingRepairActionButton';
import AwaitingExchangeActionButton from './AwaitingExchangeActionButton';
import CustomerKeepsProductPaidActionButton from './CustomerKeepsProductPaidActionButton';
import HandleReturnItemButton from './HandleReturnItemButton';

interface ProcessReturnItemPopoverButtonProps {
  returnItem: ReturnItem;
}

export default function ProcessReturnItemPopoverButton({
  returnItem,
}: ProcessReturnItemPopoverButtonProps) {
  const { t } = useTranslation();

  return (
    <PopoverButton
      id={'process-return-item'}
      button={<ActionButton label={t(tokens.automator.returns.actions.process_return)} />}
      content={
        <Stack
          direction="column"
          padding={3}
          gap={0.5}
        >
          <OnHoldActionButton
            returnItemId={returnItem.id}
            currentStatus={returnItem.status}
            showLabel={true}
          />

          <AwaitingRepairActionButton
            returnItemId={returnItem.id}
            currentStatus={returnItem.status}
            showLabel={true}
          />

          <AwaitingExchangeActionButton
            returnItemId={returnItem.id}
            currentStatus={returnItem.status}
            showLabel={true}
          />

          <CustomerKeepsProductPaidActionButton
            returnItemId={returnItem.id}
            currentStatus={returnItem.status}
            showLabel={true}
          />

          <HandleReturnItemButton
            type="ACCEPT"
            returnItem={returnItem}
          />

          <HandleReturnItemButton
            type="REJECT"
            returnItem={returnItem}
          />
        </Stack>
      }
    />
  );
}
