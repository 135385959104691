import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import InventoryItems from '../../../domain/automator/stock/InventoryItems';
import PaginatedResponse from '../../page/PaginatedResponse';
import { SortDirection } from '../../page/SortDirection';
import { InventoryItemsQueryKey } from 'src/api/queryKeys/InventoryItemsQueryKey';

export const useFetchInventoryItems = (
  page: number,
  size: number,
  sort: InventoryItemsSort,
  direction: SortDirection,
  productGroupId?: number,
  query?: string
) => {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    sort: `${sort},${direction}`,
    ...(productGroupId !== undefined && { product_group_id: productGroupId.toString() }),
    ...(query && { query }),
  });

  const url = useAutomatorApiAccountUrl(`${INVENTORY_ITEMS}?${queryParams.toString()}`);

  return useFetchData<PaginatedResponse<InventoryItems>>(
    url,
    InventoryItemsQueryKey(page, size, sort, direction, productGroupId, query)
  );
};

export const enum InventoryItemsSort {
  TITLE = 'TITLE',
  CURRENT_STOCK = 'CURRENT_STOCK',
  DAYS_TILL_DEPLETION_30D = 'DAYS_TILL_DEPLETION_30D',
  TOTAL_VALUE_IN_CENTS = 'TOTAL_VALUE_IN_CENTS',
  AVERAGE_SALES_PER_DAY_30D = 'AVERAGE_SALES_PER_DAY_30D',
}
