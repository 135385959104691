import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import Stack from '@mui/material/Stack';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';
import { useBulkUpdateInventoryItemLeadTime } from '../../../../api/automator/stock/useBulkUpdateInventoryItemLeadTime';
import { SupplyChainPhaseDropdown } from './SupplyChainPhaseDropdown';
import { ProductGroupDropdown } from '../../products/components/ProductGroupDropdown';

interface UpdateProductGroupSupplyChainPhaseFormProps {
  type: SupplyChainPhaseType;
  onClick?: () => void;
}

export const UpdateProductGroupSupplyChainPhaseForm = ({
  type,
  onClick,
}: UpdateProductGroupSupplyChainPhaseFormProps) => {
  const [productGroupId, setProductGroupId] = useState<number | null>(null);
  const [supplyChainPhaseId, setSupplyChainPhaseId] = useState<number | null>(null);

  const { mutate: update, isLoading } = useBulkUpdateInventoryItemLeadTime();

  const { t } = useTranslation();

  const onBulkUpdate = (
    productGroupId: number,
    supplyChainPhaseId: number,
    type: SupplyChainPhaseType
  ) => {
    update(
      {
        product_group_id: productGroupId,
        manufacturing_supply_chain_phase_id:
          type == SupplyChainPhaseType.MANUFACTURING ? supplyChainPhaseId : null,
        transport_supply_chain_phase_id:
          type == SupplyChainPhaseType.TRANSPORT ? supplyChainPhaseId : null,
      },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.stock.settings.supply_chain_phases.bulk_update_success));
          onClick?.();
        },
      }
    );
  };

  return (
    <Stack
      direction="column"
      gap={2}
    >
      <ProductGroupDropdown
        selected={productGroupId}
        onSelect={setProductGroupId}
      />

      <SupplyChainPhaseDropdown
        type={type}
        selected={supplyChainPhaseId}
        onSelect={setSupplyChainPhaseId}
      />

      <ActionButton
        isDisabled={!productGroupId || !supplyChainPhaseId}
        onClick={() => onBulkUpdate(productGroupId!, supplyChainPhaseId!, type)}
        isLoading={isLoading}
        label={tokens.automator.stock.supply_chain_phase_dialog.update_supply_chain_phase}
      />
    </Stack>
  );
};
