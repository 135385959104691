import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { InventoryItemListTable } from './components/InventoryItemListTable';
import StockTabs, { StockTab } from './components/StockTabs';
import { useState } from 'react';
import { ProductStockSummariesInsightListTable } from './components/ProductStockSummariesInsightListTable';
import { RestocksListTable } from './components/RestocksListTable';
import { RestockItemStatus } from '../../../domain/automator/stock/RestockItemStatus';
import { useOpenDialog } from '../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../store/dialogs/DialogId';
import ActionButton from '../../../components/ActionButton';
import { useTranslation } from 'react-i18next';
import { StockSettingsView } from './components/StockSettingsView';
import { RecommendedRestockReportsTable } from './components/RecommendedRestockReportsTable';

const StockPage = () => {
  const [tab, setCurrentTab] = useState<StockTab>(StockTab.CURRENT_STOCK);

  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.RESTOCK_SUPPLIER_OPTION);

  return (
    <AccountPage
      buttons={[
        <ActionButton
          key={1}
          label={t(tokens.automator.stock.restock_dialog.restock)}
          onClick={() => openDialog({ inventoryItem: undefined })}
          color="primary"
        />,
      ]}
      title={tokens.automator.stock.stock}
    >
      <StockTabs
        currentTab={tab}
        setTab={setCurrentTab}
      />

      {tab == StockTab.CURRENT_STOCK && <InventoryItemListTable />}

      {tab == StockTab.STOCK_INSIGHT && <ProductStockSummariesInsightListTable />}

      {tab == StockTab.ORDERED_RESTOCK && <RestocksListTable status={RestockItemStatus.ORDERED} />}

      {tab == StockTab.SETTINGS && <StockSettingsView />}

      {tab == StockTab.RECOMMENDED_RESTOCK && <RecommendedRestockReportsTable />}
    </AccountPage>
  );
};

export default StockPage;
