import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { tokens } from '../../../../locales/translationTokens';
import { EditRestockItem, RestockItemForm, RestockItemMetricOption } from './RestockItemForm';
import InventoryStock from '../../../../domain/automator/stock/InventoryStock';
import { toast } from 'react-hot-toast';
import { useUpdateInventoryStock } from '../../../../api/automator/stock/useUpdateInventoryStock';
import { RestockItemStatus } from '../../../../domain/automator/stock/RestockItemStatus';
import { useState } from 'react';
import ActionButton from '../../../../components/ActionButton';

interface UpdateInventoryStockFormProps {
  onClick?: () => void;
  inventoryStock: InventoryStock;
}

export const UpdateInventoryStockForm = ({
  inventoryStock,
  onClick,
}: UpdateInventoryStockFormProps) => {
  const initial = () => {
    return {
      // NOTE: hacky way to be able to use the same form as the restock form
      inventoryItem: {
        id: inventoryStock.stockValues.inventoryItemId,
        supplierIds: [],
        salesVariants: [],
      },
      supplierId: null,
      quantity: inventoryStock.stockValues.quantity,
      unitPriceInCents: inventoryStock.stockValues.unitPriceInCents,
      unitTransportCostInCents: inventoryStock.stockValues.unitTransportCostInCents,
      totalCubicMeters: inventoryStock.stockValues.totalCubicMeters,
      totalWeightInKg: inventoryStock.stockValues.totalWeightInKg,
      status: RestockItemStatus.DELIVERED,
      daysUntilDelivery: 0,
      metricOption: RestockItemMetricOption.BY_UNIT,
    };
  };

  const { mutate: updateProductStock } = useUpdateInventoryStock(
    inventoryStock.stockValues.inventoryItemId,
    inventoryStock.id
  );

  const { t } = useTranslation();

  const [item, setItem] = useState<EditRestockItem>(initial());

  const onSave = () => {
    updateProductStock(
      {
        supplier_id: item.supplierId,
        quantity: item.quantity,
        unit_price_in_cents:
          item.metricOption == RestockItemMetricOption.TOTAL && item.unitPriceInCents
            ? item.unitPriceInCents / item.quantity
            : item.unitPriceInCents,
        unit_transport_cost_in_cents:
          item.metricOption == RestockItemMetricOption.TOTAL && item.unitTransportCostInCents
            ? item.unitTransportCostInCents / item.quantity
            : item.unitTransportCostInCents,
        total_cubic_meters:
          item.metricOption == RestockItemMetricOption.TOTAL && item.totalCubicMeters
            ? item.totalCubicMeters / item.quantity
            : item.totalCubicMeters,
        total_weight_in_kg:
          item.metricOption == RestockItemMetricOption.TOTAL && item.totalWeightInKg
            ? item.totalWeightInKg / item.quantity
            : item.totalWeightInKg,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.stock.update_product_stock.product_stock_updated));
        },
      }
    );
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <RestockItemForm
        supplierId={null}
        item={initial()}
        onUpdate={(item) => setItem(item)}
        canUpdateQuantity={false}
      />

      <ActionButton
        color="primary"
        label={t(tokens.automator.stock.restock_dialog.save)}
        onClick={() => onSave && onSave()}
      />
    </Stack>
  );
};
