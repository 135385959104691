import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import ReducedInventoryItem from '../../../../domain/automator/stock/ReducedInventoryItem';
import { RestockSupplierOptionForm } from './RestockSupplierOptionForm';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';

export const RestockSupplierOptionDialog = () => {
  const id = DialogId.RESTOCK_SUPPLIER_OPTION;

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const openDialog = useOpenDialog(DialogId.CREATE_RESTOCK);

  const { t } = useTranslation();

  const onSelect = (supplierId: number | null) => {
    closeDialog();

    openDialog({
      supplierId,
      inventoryItem: data!.inventoryItem,
      onCreate: data!.onCreate,
    });
  };

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'sm'}
          title={t(tokens.automator.stock.restock_dialog.restock)}
        >
          <RestockSupplierOptionForm onSelect={onSelect} />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface RestockSupplierOptionDialogData {
  inventoryItem?: ReducedInventoryItem;
  onCreate?: () => void;
}
