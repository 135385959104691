import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import ActionButton from '../../../../components/ActionButton';
import { toast } from 'react-hot-toast';
import { useFetchInventoryItemByProductId } from '../../../../api/automator/stock/useFetchInventoryItemByProductId';
import { useAddSalesVariantToInventoryItem } from '../../../../api/automator/stock/useAddSalesVariantToInventoryItem';

interface AddSalesVariantToInventoryItemActionButtonProps {
  onAdd: () => void;
  inventoryItem: InventoryItem;
  productId: number;
}

export const AddSalesVariantToInventoryItemActionButton = ({
  inventoryItem,
  productId,
}: AddSalesVariantToInventoryItemActionButtonProps) => {
  const { t } = useTranslation();

  const { data: oldInventoryItem, isLoading } = useFetchInventoryItemByProductId(productId);

  const { mutate: addSalesVariant, isLoading: isLoadingAddSalesVariant } =
    useAddSalesVariantToInventoryItem(inventoryItem.id);

  if (isLoading) {
    return <span>...</span>;
  }

  const onAdd = () => {
    if (oldInventoryItem!.salesVariants.length != 1) {
      toast.error(t(tokens.automator.stock.add_sales_variant_dialog.error));
      console.error('Old inventory item does not have exactly one sales variant');
      return;
    }

    addSalesVariant(
      {
        sales_variant_id: oldInventoryItem!.salesVariants[0].id,
      },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.stock.restock_dialog.restock_created));
        },
      }
    );
  };

  return (
    <ActionButton
      onClick={onAdd}
      isLoading={isLoadingAddSalesVariant}
      label={t(tokens.automator.stock.add_sales_variant_dialog.add_sales_variant)}
    />
  );
};
