// AwaitingExchangeActionButton.tsx
import { Sync } from '@mui/icons-material';
import ActionButton from '../../../../components/ActionButton';
import { useReturnItemAwaitingExchange } from '../../../../api/automator/returns/useReturnItemAwaitingExchange';
import { ReturnItemStatus } from '../../../../domain/automator/returns/ReturnItemStatus';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface AwaitingExchangeActionButtonProps {
  returnItemId: number;
  currentStatus: ReturnItemStatus;
  onSuccess?: () => void;
  showLabel?: boolean;
}

const AwaitingExchangeActionButton = ({
  returnItemId,
  currentStatus,
  onSuccess,
  showLabel,
}: AwaitingExchangeActionButtonProps) => {
  const { t } = useTranslation();
  const { mutate: returnItemAwaitingExchange, isLoading: returnItemAwaitingExchangeIsLoading } =
    useReturnItemAwaitingExchange(returnItemId);

  const handleClick = () => {
    returnItemAwaitingExchange(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.returns.actions.set_to_awaiting_exchange));
        onSuccess?.();
      },
    });
  };

  return (
    <ActionButton
      icon={<Sync />}
      label={showLabel ? t(tokens.automator.returns.actions.set_to_awaiting_exchange) : ''}
      onClick={handleClick}
      isDisabled={
        currentStatus === ReturnItemStatus.REPAIR || currentStatus === ReturnItemStatus.EXCHANGE
      }
      isLoading={returnItemAwaitingExchangeIsLoading}
      variant={currentStatus === ReturnItemStatus.EXCHANGE ? 'contained' : 'outlined'}
      size="small"
    />
  );
};

export default AwaitingExchangeActionButton;
