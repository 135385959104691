import { RESTOCK_ITEMS } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';
import { RestockItemStatus } from '../../../domain/automator/stock/RestockItemStatus';
import Restock from '../../../domain/automator/stock/Restock';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import { InventoryStocksQueryKey } from '../../queryKeys/InventoryStocksQueryKey';
import { InventoryItemStockAdjustmentsQueryKey } from '../../queryKeys/InventoryItemStockAdjustmentsQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';
import { InventoryItemSuppliersQueryKey } from '../../queryKeys/InventoryItemSuppliersQueryKey';
import { InventoryItemRestockItemsQueryKey } from '../../queryKeys/InventoryItemRestockItemsQueryKey';

export const useUpdateRestockItem = (restockItem: number) => {
  const url = useAutomatorApiAccountUrl(RESTOCK_ITEMS + `/${restockItem}`);

  return useAuthorizedPost<Restock, UpdateRestockItemData>(url, [
    InventoryItemsQueryKey(),
    RestocksQueryKey(RestockItemStatus.DELIVERED),
    RestocksQueryKey(RestockItemStatus.ORDERED),
    InventoryItemRestockItemsQueryKey(),
    InventoryStocksQueryKey(),
    InventoryItemStockAdjustmentsQueryKey(),
    InventoryItemInsightQueryKey(),
    InventoryItemSuppliersQueryKey(),
  ]);
};

export interface UpdateRestockItemData {
  supplier_id: number | null;
  quantity: number;
  unit_price_in_cents: number | null;
  unit_transport_cost_in_cents: number | null;
  total_cubic_meters: number | null;
  total_weight_in_kg: number | null;
  expected_delivery_date: string;
}
