import { PRODUCTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { InventoryItemByProductIdQueryKey } from '../../queryKeys/InventoryItemByProductIdQueryKey';
import InventoryItem from '../../../domain/automator/stock/InventoryItem';

export const useFetchInventoryItemByProductId = (productId: number) => {
  const url = useAutomatorApiAccountUrl(`${PRODUCTS_PATH}/${productId}/inventory-item`);
  return useFetchData<InventoryItem>(url, [InventoryItemByProductIdQueryKey(productId)], {
    enabled: productId != 0,
  });
};
