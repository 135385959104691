import { Launch } from '@mui/icons-material';
import { tokens } from '../../../locales/translationTokens';
import { ActionIcon } from './components/ActionIcon';

interface ProductPageIconProps {
  productPageUrl: string;
  iconSize?: number;
}

export const ProductPageIcon = ({ productPageUrl, iconSize }: ProductPageIconProps) => {
  return (
    <ActionIcon
      onClick={() => window.open(productPageUrl, '_blank')}
      tooltip={tokens.automator.orders.open_product_page}
      iconSize={iconSize || 20}
      icon={<Launch />}
    />
  );
};
