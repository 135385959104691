import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { RESTOCK_ITEMS } from '../automatorApiPaths';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import { InventoryItemRestockItemsQueryKey } from '../../queryKeys/InventoryItemRestockItemsQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';

export const useRestockItemDelivered = (restockItemId: number, inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(RESTOCK_ITEMS + `/${restockItemId}/delivered`);
  return useAuthorizedPost<undefined, BulkUpdateInventoryItemLeadTimeData>(url, [
    InventoryItemsQueryKey(),
    InventoryItemRestockItemsQueryKey(inventoryItemId),
    InventoryItemInsightQueryKey(inventoryItemId),
  ]);
};

export interface BulkUpdateInventoryItemLeadTimeData {
  product_group_id: number;
  transport_supply_chain_phase_id: number | null;
  manufacturing_supply_chain_phase_id: number | null;
}
