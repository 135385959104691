import ActionButton from '../../../../components/ActionButton';
import { useRestockDelivered } from '../../../../api/automator/stock/useRestockDelivered';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';

interface RestockDeliveredActionButtonProps {
  restockId: number;
}

export const RestockDeliveredActionButton = ({ restockId }: RestockDeliveredActionButtonProps) => {
  const { mutate: doDeliver, isLoading } = useRestockDelivered(restockId);

  const { t } = useTranslation();

  const onDeliver = () => {
    doDeliver(undefined, {
      onSuccess: () => {
        toast.success(t(tokens.automator.stock.restock_delivered));
      },
    });
  };

  return (
    <ActionButton
      label={t(tokens.automator.stock.restock_delivered)}
      onClick={onDeliver}
      isLoading={isLoading}
      variant="outlined"
    />
  );
};
