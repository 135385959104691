import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import { Box } from '@mui/system';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { SupplierDropdownSelect } from '../components/SupplierDropdownSelect';

interface RestockFormProps {
  onSelect?: (supplierId: number | null) => void;
}

enum RestockFormSupplierOption {
  ALL = 'ALL',
  SELECTED = 'SELECTED',
}

export const RestockSupplierOptionForm = ({ onSelect }: RestockFormProps) => {
  const { t } = useTranslation();

  const [supplierOption, setSupplierOption] = useState<RestockFormSupplierOption | null>(null);

  const onUpdateSupplierOption = (supplierOption: RestockFormSupplierOption) => {
    if (supplierOption == RestockFormSupplierOption.ALL) {
      onSelect?.(null);
    }
    setSupplierOption(supplierOption);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <Stack
        direction="row"
        gap={2}
      >
        <ToggleButtonGroup
          value={supplierOption}
          exclusive
          onChange={(_, newValue) => {
            if (newValue) onUpdateSupplierOption(newValue);
          }}
          aria-label={
            t(tokens.automator.stock.restock_dialog.supplier_option.supplier_option) as string
          }
        >
          <ToggleButton
            value={RestockFormSupplierOption.ALL}
            aria-label={t(tokens.automator.stock.restock_dialog.supplier_option.all) as string}
          >
            {t(tokens.automator.stock.restock_dialog.supplier_option.all)}
          </ToggleButton>
          <ToggleButton
            value={RestockFormSupplierOption.SELECTED}
            aria-label={t(tokens.automator.stock.restock_dialog.supplier_option.supplier) as string}
          >
            {t(tokens.automator.stock.restock_dialog.supplier_option.supplier)}
          </ToggleButton>
        </ToggleButtonGroup>

        {supplierOption == RestockFormSupplierOption.SELECTED && (
          <Box>
            <SupplierDropdownSelect
              supplierId={null}
              onSelect={onSelect!}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
