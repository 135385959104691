import { PRODUCT_GROUPS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { ProductGroupsQueryKey } from '../../queryKeys/ProductGroupsQueryKey';
import ProductGroup from '../../../domain/automator/products/ProductGroup';
import { useFetchData } from '../../useFetchData';

export const useFetchProductGroups = () => {
  const url = useAutomatorApiAccountUrl(`${PRODUCT_GROUPS_PATH}`);
  return useFetchData<ProductGroup[]>(url, ProductGroupsQueryKey());
};
