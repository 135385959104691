import { useState } from 'react';
import { useCreateSupplyChainPhase } from '../../../../api/automator/stock/useCreateSupplyChainPhase';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import TextField from '@mui/material/TextField';
import ActionButton from '../../../../components/ActionButton';
import Stack from '@mui/material/Stack';

interface CreateSupplyChainPhaseFormProps {
  onClick?: () => void;
  type: SupplyChainPhaseType;
}

export const CreateSupplyChainPhaseForm = ({ onClick, type }: CreateSupplyChainPhaseFormProps) => {
  const [name, setName] = useState<string>('');
  const [duration, setDuration] = useState<number>(10);

  const { mutate: createSupplyChainPhase, isLoading } = useCreateSupplyChainPhase();

  const { t } = useTranslation();

  const doCreate = (name: string, duration: number) => {
    createSupplyChainPhase(
      {
        name: name,
        duration_in_days: duration,
        type: type,
      },
      {
        onSuccess: async () => {
          toast.success(
            t(tokens.automator.stock.supply_chain_phase_dialog.supply_chain_phase_created)
          );
          onClick?.();
        },
      }
    );
  };

  return (
    <Stack
      direction="column"
      gap={2}
    >
      <TextField
        label={t(tokens.automator.stock.supply_chain_phase_dialog.name)}
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />

      <TextField
        label={t(tokens.automator.stock.supply_chain_phase_dialog.duration_in_days)}
        variant="outlined"
        type="number"
        value={duration}
        onChange={(e) => setDuration(Number(e.target.value))}
        required
      />

      <TextField
        label={t(tokens.automator.stock.supply_chain_phase_dialog.type)}
        variant="outlined"
        value={type}
        disabled
      />

      <ActionButton
        onClick={() => doCreate(name, duration)}
        isLoading={isLoading}
        label={tokens.automator.stock.supply_chain_phase_dialog.create_supply_chain_phase}
      />
    </Stack>
  );
};
