import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { StocktakeForm } from '../components/StocktakeForm';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';

export const StocktakeDialog = () => {
  const { isOpen, data } = useIsDialogOpen(DialogId.STOCKTAKE);
  const closeDialog = useCloseDialog(DialogId.STOCKTAKE);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={DialogId.STOCKTAKE}
          title={t(tokens.automator.stock.stocktake_dialog.stocktake)}
        >
          <StocktakeForm
            onClick={() => {
              closeDialog();
              data.onCreate?.();
            }}
            inventoryItem={data.inventoryItem}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface StocktakeDialogData {
  inventoryItem: InventoryItem;
  onCreate?: () => void;
}
