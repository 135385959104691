import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import ReducedInventoryItems from '../../../domain/automator/stock/ReducedInventoryItems';
import { ReducedInventoryItemsQueryKey } from '../../queryKeys/ReducedInventoryItemsQueryKey';

export const useFetchReducedInventoryItems = () => {
  const url = useAutomatorApiAccountUrl(`${INVENTORY_ITEMS}/reduced`);

  return useFetchData<ReducedInventoryItems>(url, ReducedInventoryItemsQueryKey());
};
