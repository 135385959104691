import { RECOMMENDED_RESTOCK_REPORTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import RecommendedRestockReport from '../../../domain/automator/stock/RecommendedRestockReport';
import { useFetchData } from '../../useFetchData';
import { RecommendedRestockReportsQueryKey } from '../../queryKeys/RecommendedRestockReportsQueryKey';

export const useFetchRecommendedRestockReport = () => {
  const url = useAutomatorApiAccountUrl(RECOMMENDED_RESTOCK_REPORTS_PATH);
  return useFetchData<RecommendedRestockReport[]>(url, RecommendedRestockReportsQueryKey());
};
