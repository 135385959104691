import Stack from '@mui/material/Stack';

import * as React from 'react';
import { useState } from 'react';
import { InventoryItemRestockItemsListTable } from './InventoryItemRestockItemsListTable';
import { InventoryItemStockAdjustmentsListTable } from './InventoryItemStockAdjustmentsListTable';
import { StockAdjustmentType } from '../../../../domain/automator/stock/StockAdjustmentType';
import { InventoryItemSuppliersListTable } from './InventoryItemSuppliersListTable';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import InventoryItemDetailsTab, { InventoryItemDetailsTabs } from './InventoryItemDetailsTab';
import { UpdateInventoryItemNoteForm } from './UpdateInventoryItemNoteForm';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { InventoryItemReturnsListTable } from './InventoryItemReturnsListTable';
import { InventoryItemSettingsView } from './InventoryItemSettingsView';
import { InventoryItemInsightView } from './InventoryItemInsightView';

interface InventoryItemDetailsProps {
  inventoryItem: InventoryItem;
}

export const InventoryItemDetails = ({ inventoryItem }: InventoryItemDetailsProps) => {
  const [tab, setTab] = useState(InventoryItemDetailsTabs.INVENTORY_ITEM_INSIGHT);

  const openUpdateOffersDialog = useOpenDialog(DialogId.UPDATE_INVENTORY_ITEM_OFFER_LISTINGS);

  const openCreateRestockDialog = useOpenDialog(DialogId.CREATE_RESTOCK);

  return (
    <Stack
      direction="column"
      gap={2}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <InventoryItemDetailsTab
          currentTab={tab}
          setTab={setTab}
        />

        <Stack
          direction="row"
          gap={1}
          marginTop={1}
          paddingRight={5}
        >
          <ActionButton
            size="small"
            variant="outlined"
            onClick={() =>
              openUpdateOffersDialog({
                salesVariants: inventoryItem.salesVariants,
              })
            }
            label={tokens.automator.orders.dialogs.update_offer.update_offers}
          />

          <ActionButton
            size="small"
            onClick={() =>
              openCreateRestockDialog({
                inventoryItem: inventoryItem,
                supplierId: null,
              })
            }
            label={tokens.automator.stock.restock_dialog.restock}
          />
        </Stack>
      </Stack>

      {tab === InventoryItemDetailsTabs.RESTOCKS && (
        <InventoryItemRestockItemsListTable inventoryItemId={inventoryItem.id} />
      )}

      {tab === InventoryItemDetailsTabs.INVENTORY_ITEM_INSIGHT && (
        <InventoryItemInsightView inventoryItem={inventoryItem} />
      )}

      {tab === InventoryItemDetailsTabs.SALES && (
        <InventoryItemStockAdjustmentsListTable
          inventoryItem={inventoryItem}
          type={StockAdjustmentType.SALES}
        />
      )}

      {tab === InventoryItemDetailsTabs.RETURNS && (
        <InventoryItemReturnsListTable inventoryItemId={inventoryItem.id} />
      )}

      {tab === InventoryItemDetailsTabs.SUPPLIERS && (
        <InventoryItemSuppliersListTable inventoryItemId={inventoryItem.id} />
      )}

      {tab === InventoryItemDetailsTabs.SETTINGS && (
        <InventoryItemSettingsView inventoryItem={inventoryItem} />
      )}

      {tab === InventoryItemDetailsTabs.NOTES && (
        <UpdateInventoryItemNoteForm inventoryItemId={inventoryItem.id} />
      )}
    </Stack>
  );
};
