import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';

export const useUpdateInventoryItemLeadTime = (inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(INVENTORY_ITEMS + `/${inventoryItemId}/update-lead-time`);
  return useAuthorizedPost<undefined, UpdateInventoryItemLeadTimeData>(url, [
    InventoryItemsQueryKey(),
  ]);
};

export interface UpdateInventoryItemLeadTimeData {
  transport_duration_in_days: number | null;
  manufacturing_duration_in_days: number | null;
  transport_supply_chain_phase_id: number | null;
  manufacturing_supply_chain_phase_id: number | null;
}
