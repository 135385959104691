import { Box } from '@mui/material';
import { useDetermineDepletionLevel } from '../services/useDetermineDepletionLevel'; // or wherever it is
import { useDetermineDepletionLevelColor } from '../services/useDetermineDepletionLevelColor'; // if you need the color

const DepletionSignal = ({
  leadTimeDays,
  daysTillDepletion,
}: {
  leadTimeDays: number;
  daysTillDepletion?: number;
}) => {
  const depletionLevel = useDetermineDepletionLevel(leadTimeDays, daysTillDepletion);

  const circleColor = useDetermineDepletionLevelColor(leadTimeDays, daysTillDepletion);

  const barDefs = [
    { x: 2, y: 22, height: 2 }, // bar 1
    { x: 5, y: 20, height: 4 }, // bar 2
    { x: 8, y: 18, height: 6 }, // bar 3
    { x: 11, y: 16, height: 8 }, // bar 4
    { x: 14, y: 14, height: 10 }, // bar 5
    { x: 17, y: 12, height: 12 }, // bar 6
  ];

  return (
    <Box
      component="svg"
      width={96}
      height={96}
      viewBox="0 0 24 36"
    >
      {barDefs.map(({ x, y, height }, index) => {
        const barNumber = index + 1;

        const isFilled = depletionLevel !== undefined && barNumber <= depletionLevel;

        return (
          <rect
            key={barNumber}
            x={x}
            y={y}
            width={2}
            height={height}
            rx={1}
            fill={isFilled ? circleColor : 'none'}
            stroke={isFilled ? 'none' : circleColor}
            strokeWidth={isFilled ? 0 : 0.2}
          />
        );
      })}
    </Box>
  );
};

export default DepletionSignal;
