// InventoryItemSupplyChainPhaseSettings.tsx

import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';
import { useUpdateInventoryItemLeadTime } from '../../../../api/automator/stock/useUpdateInventoryItemLeadTime';
import { useFetchSupplyChainPhases } from '../../../../api/automator/stock/useFetchSupplyChainPhases';
import { tokens } from '../../../../locales/translationTokens';
import { InventoryItemSupplyChainPhaseSetting } from './InventoryItemSupplyChainPhaseSetting';
import { Box } from '@mui/system';

interface InventoryItemSupplyChainPhaseSettingsProps {
  inventoryItem: InventoryItem;
}

export const InventoryItemSupplyChainPhaseSettings = ({
  inventoryItem,
}: InventoryItemSupplyChainPhaseSettingsProps) => {
  const { mutate: updateLeadTime, isLoading } = useUpdateInventoryItemLeadTime(inventoryItem.id);
  const { data: supplyChainPhases, isLoading: isLoadingSupplyChainPhases } =
    useFetchSupplyChainPhases();

  const [isUsingPhaseForManufacturing, setIsUsingPhaseForManufacturing] = useState(
    inventoryItem.leadTime.manufacturingSupplyChainPhaseId != null
  );
  const [isUsingPhaseForTransport, setIsUsingPhaseForTransport] = useState(
    inventoryItem.leadTime.transportSupplyChainPhaseId != null
  );

  const [manufacturingSupplyChainPhaseId, setManufacturingSupplyChainPhaseId] = useState(
    inventoryItem.leadTime.manufacturingSupplyChainPhaseId
  );
  const [transportSupplyChainPhaseId, setTransportSupplyChainPhaseId] = useState(
    inventoryItem.leadTime.transportSupplyChainPhaseId
  );

  const [manufacturingDays, setManufacturingDays] = useState(
    inventoryItem.leadTime.manufacturingDurationInDays
  );
  const [transportDays, setTransportDays] = useState(
    inventoryItem.leadTime.transportDurationInDays
  );

  const { t } = useTranslation();

  const manufacturingPhaseLeadTime = isUsingPhaseForManufacturing
    ? supplyChainPhases?.find((p) => p.id === manufacturingSupplyChainPhaseId)?.durationInDays ?? 0
    : manufacturingDays;

  const transportPhaseLeadTime = isUsingPhaseForTransport
    ? supplyChainPhases?.find((p) => p.id === transportSupplyChainPhaseId)?.durationInDays ?? 0
    : transportDays;

  const totalLeadTime = manufacturingPhaseLeadTime + transportPhaseLeadTime;

  const doUpdateLeadTime = () => {
    updateLeadTime(
      {
        manufacturing_supply_chain_phase_id: isUsingPhaseForManufacturing
          ? manufacturingSupplyChainPhaseId
          : null,
        manufacturing_duration_in_days: isUsingPhaseForManufacturing ? null : manufacturingDays,
        transport_supply_chain_phase_id: isUsingPhaseForTransport
          ? transportSupplyChainPhaseId
          : null,
        transport_duration_in_days: isUsingPhaseForTransport ? null : transportDays,
      },
      {
        onSuccess: () => {
          toast.success(
            t(tokens.automator.stock.settings.supply_chain_phases.updated_inventory_item_lead_time)
          );
        },
      }
    );
  };

  if (isLoading || isLoadingSupplyChainPhases || !supplyChainPhases) {
    return <div>Loading...</div>;
  }

  return (
    <Stack
      direction="column"
      gap={2}
      padding={2.5}
    >
      <InventoryItemSupplyChainPhaseSetting
        title={t(tokens.automator.stock.settings.supply_chain_phases.type.transport)}
        isUsingPhase={isUsingPhaseForTransport}
        onIsUsingPhaseChange={(val) => setIsUsingPhaseForTransport(val)}
        supplyChainPhases={supplyChainPhases!.filter(
          (phase) => phase.type === SupplyChainPhaseType.TRANSPORT
        )}
        selectedPhaseId={transportSupplyChainPhaseId}
        onSelectedPhaseIdChange={(val) => setTransportSupplyChainPhaseId(val)}
        days={transportDays}
        onDaysChange={(val) => setTransportDays(val)}
        filterPhaseType={SupplyChainPhaseType.TRANSPORT}
      />

      <InventoryItemSupplyChainPhaseSetting
        title={t(tokens.automator.stock.settings.supply_chain_phases.type.manufacturing)}
        isUsingPhase={isUsingPhaseForManufacturing}
        onIsUsingPhaseChange={(val) => setIsUsingPhaseForManufacturing(val)}
        supplyChainPhases={supplyChainPhases!.filter(
          (phase) => phase.type === SupplyChainPhaseType.MANUFACTURING
        )}
        selectedPhaseId={manufacturingSupplyChainPhaseId}
        onSelectedPhaseIdChange={(val) => setManufacturingSupplyChainPhaseId(val)}
        days={manufacturingDays}
        onDaysChange={(val) => setManufacturingDays(val)}
        filterPhaseType={SupplyChainPhaseType.MANUFACTURING}
      />

      <Stack
        direction="row"
        gap={1}
        alignItems="flex-end"
      >
        <Typography
          fontSize={20}
          fontWeight={600}
          variant="body1"
        >
          {t(tokens.automator.stock.settings.supply_chain_phases.total_lead_time) + ':'}
        </Typography>

        <Typography
          fontSize={20}
          fontWeight={600}
          variant="body1"
        >
          {totalLeadTime + ' ' + t(tokens.automator.stock.settings.supply_chain_phases.days)}
        </Typography>
      </Stack>

      <Box>
        <Button
          onClick={doUpdateLeadTime}
          variant="contained"
          disabled={isLoading}
        >
          {t(tokens.automator.stock.settings.supply_chain_phases.updated_inventory_item_lead_time)}
        </Button>
      </Box>
    </Stack>
  );
};
