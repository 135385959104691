import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useFetchInventoryItemSuppliers } from '../../../../api/automator/stock/useFetchInventoryItemSuppliers';
import { SupplierTableRow } from './SupplierTableRow';
import { SupplierDropdownSelect } from './SupplierDropdownSelect';
import { toast } from 'react-hot-toast';
import { useAddSupplierToInventoryItem } from '../../../../api/automator/stock/useAddSupplierToInventoryItem';

interface ProductSuppliersListTableProps {
  inventoryItemId: number;
}

export const InventoryItemSuppliersListTable = ({
  inventoryItemId,
}: ProductSuppliersListTableProps) => {
  const { data, isLoading: isLoadingSuppliers } = useFetchInventoryItemSuppliers(inventoryItemId);

  const { t } = useTranslation();

  const { mutate: addSupplier } = useAddSupplierToInventoryItem(inventoryItemId);

  const onSave = (supplierId: number) => {
    if (supplierId === null) {
      return;
    }

    addSupplier(
      { supplier_id: supplierId },
      {
        onSuccess: async () => {
          toast.success(t(tokens.automator.stock.supplier_dialog.supplier_created));
        },
      }
    );
  };

  if (isLoadingSuppliers) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  const suppliers = data!.suppliers;

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.stock.supplier_dialog.company_name)}</TableCell>
            <TableCell>{t(tokens.automator.stock.supplier_dialog.contact_name)}</TableCell>
            <TableCell>{t(tokens.automator.stock.supplier_dialog.hs_code)}</TableCell>
            <TableCell>{t(tokens.automator.stock.supplier_dialog.email_address)}</TableCell>
            <TableCell>{t(tokens.automator.stock.supplier_dialog.url)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {suppliers!.map((supplier) => {
            return (
              <SupplierTableRow
                key={supplier.id}
                supplier={supplier}
              />
            );
          })}

          <TableRow>
            <TableCell colSpan={6}>
              <SupplierDropdownSelect
                supplierId={null}
                inventoryItemId={inventoryItemId}
                onSelect={onSave}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
