import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Restock from '../../../../domain/automator/stock/Restock';
import { RestockItemStatus } from '../../../../domain/automator/stock/RestockItemStatus';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import { Box } from '@mui/system';
import { RestockDeliveredActionButton } from './RestockDeliveredActionButton';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import Stack from '@mui/material/Stack';

interface RestockTableRowProps {
  restock: Restock;
}

export const RestockTableRow = ({ restock }: RestockTableRowProps) => {
  const { t } = useTranslation();

  const editRestock = useOpenDialog(DialogId.EDIT_RESTOCK);

  return (
    <TableRow>
      <TableCell>
        <Typography>{restock.id}#</Typography>
      </TableCell>

      <TableCell>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(10, 1fr)',
            alignItems: 'center',
            gap: 0.2,
          }}
        >
          {restock.salesVariants.map((variant, index) => (
            <TooltippedProductThumbnail
              key={index}
              productTitle={variant.title}
              height={30}
              thumbnailUrl={variant.thumbnailUrl}
            />
          ))}
        </Box>
      </TableCell>

      <TableCell>
        <Typography>
          {getShortendAlphabeticFormatFromDate(new Date(restock.orderPlacedDate), false, true)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography>
          {getShortendAlphabeticFormatFromDate(new Date(restock.expectedDeliveryDate), false, true)}
        </Typography>
      </TableCell>

      <TableCell>
        {restock.actualDeliveryDate ? (
          <Typography>
            {getShortendAlphabeticFormatFromDate(new Date(restock.actualDeliveryDate), false, true)}
          </Typography>
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          gap={1}
        >
          {restock.status == RestockItemStatus.ORDERED && (
            <ActionButton
              label={t(tokens.automator.stock.edit_restock_dialog.edit_restock)}
              onClick={() => editRestock({ restockId: restock.id })}
              variant="outlined"
            />
          )}

          {restock.status == RestockItemStatus.ORDERED && (
            <RestockDeliveredActionButton restockId={restock.id} />
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};
