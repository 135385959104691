import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { RESTOCKS_PATH } from '../automatorApiPaths';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';
import { RestockItemStatus } from '../../../domain/automator/stock/RestockItemStatus';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import Restock from '../../../domain/automator/stock/Restock';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { InventoryStocksQueryKey } from '../../queryKeys/InventoryStocksQueryKey';
import { InventoryItemStockAdjustmentsQueryKey } from '../../queryKeys/InventoryItemStockAdjustmentsQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';
import { RestockQueryKey } from '../../queryKeys/RestockQueryKey';

export const useRestockDelivered = (restockId: number) => {
  const url = useAutomatorApiAccountUrl(RESTOCKS_PATH + `/${restockId}/delivered`);
  return useAuthorizedPost<Restock>(url, [
    RestocksQueryKey(RestockItemStatus.DELIVERED),
    RestocksQueryKey(RestockItemStatus.ORDERED),
    RestockQueryKey(),
    InventoryItemsQueryKey(),
    InventoryStocksQueryKey(),
    InventoryItemStockAdjustmentsQueryKey(),
    InventoryItemInsightQueryKey(),
  ]);
};
