import roundDownTo1Decimal from '../../../pd/helpers/roundDownTo1Decimal';
import { ToggleButtonGroup } from '@mui/material';
import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';

interface StockAverageSalesToggleButtonsProps {
  selectedDays: 7 | 14 | 30;
  onChange: (days: 7 | 14 | 30) => void;
  averageSalesPerDay7d: number | null;
  averageSalesPerDay14d: number | null;
  averageSalesPerDay30d: number | null;
}

export const StockAverageSalesToggleButtons = ({
  selectedDays,
  onChange,
  averageSalesPerDay7d,
  averageSalesPerDay14d,
  averageSalesPerDay30d,
}: StockAverageSalesToggleButtonsProps) => {
  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      value={selectedDays}
      onChange={(_, value) => {
        if (value == null) {
          return;
        }
        onChange(value as 7 | 14 | 30);
      }}
      aria-label="Platform"
    >
      <ToggleButton
        style={{ width: 50 }}
        value={7}
      >
        {roundDownTo1Decimal(averageSalesPerDay7d)}
      </ToggleButton>

      <ToggleButton
        style={{ width: 50 }}
        value={14}
      >
        {roundDownTo1Decimal(averageSalesPerDay14d)}
      </ToggleButton>

      <ToggleButton
        style={{ width: 50 }}
        value={30}
      >
        {roundDownTo1Decimal(averageSalesPerDay30d)}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
