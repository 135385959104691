import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { StockUnitPrice } from './StockUnitPrice';
import { StockUnitShippingCost } from './StockUnitShippingCost';
import { StockCubicMeters } from './StockCubicMeters';
import { StockWeight } from './StockWeight';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import { SubStockAmount } from '../SubStockAmount';
import { InventoryStockTypeChip } from './InventoryStockTypeChip';
import { InventoryStockType } from '../../../../domain/automator/stock/InventoryStockType';
import InventoryStock from '../../../../domain/automator/stock/InventoryStock';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useTranslation } from 'react-i18next';

interface StocktakeInventoryStockTableRowProps {
  inventoryStock: InventoryStock;
}

export const StocktakeInventoryStockTableRow = ({
  inventoryStock,
}: StocktakeInventoryStockTableRowProps) => {
  const openUpdateInventoryStockDialog = useOpenDialog(DialogId.UPDATE_INVENTORY_STOCK);

  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>
        <InventoryStockTypeChip type={InventoryStockType.STOCKTAKE} />
      </TableCell>

      <TableCell>
        <Typography>
          {getShortendAlphabeticFormatFromDate(new Date(inventoryStock.date), false, true)}
        </Typography>
      </TableCell>

      <TableCell>
        <SubStockAmount amount={inventoryStock.stockValues.quantity} />
      </TableCell>

      <TableCell>
        <StockUnitPrice amount={inventoryStock.stockValues.unitPriceInCents} />
      </TableCell>

      <TableCell>
        <StockUnitShippingCost amount={inventoryStock.stockValues.unitTransportCostInCents} />
      </TableCell>

      <TableCell>
        <StockCubicMeters amount={inventoryStock.stockValues.totalCubicMeters} />
      </TableCell>

      <TableCell>
        <StockWeight amount={inventoryStock.stockValues.totalWeightInKg} />
      </TableCell>

      <TableCell></TableCell>

      <TableCell align="right">
        <ActionButton
          onClick={() =>
            openUpdateInventoryStockDialog({
              inventoryStock: inventoryStock,
            })
          }
          label={t(tokens.automator.stock.update_restock_item.update_restock_item)}
          variant={'outlined'}
        />
      </TableCell>
    </TableRow>
  );
};
