import Typography from '@mui/material/Typography';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import { Tooltip } from '@mui/material';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';

interface OrderedStockInfoProps {
  inventoryItem: InventoryItem;
}

export const OrderedStockInfo = ({ inventoryItem }: OrderedStockInfoProps) => {
  return (
    <Tooltip
      enterDelay={200}
      enterNextDelay={200}
      title={
        'Aankomst: ' +
        inventoryItem.orderedStockExpectedDeliveryDates
          .map((date) => getShortendAlphabeticFormatFromDate(new Date(date), false, true))
          .join(', ')
      }
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <DirectionsBoatFilledOutlinedIcon />
        <Typography fontWeight={500}>(+{inventoryItem.orderedStock})</Typography>
      </Stack>
    </Tooltip>
  );
};
