export const useDetermineDepletionLevel = (
  leadTimeDays: number,
  daysTillDepletion?: number
): undefined | 1 | 2 | 3 | 4 | 5 | 6 => {
  if (daysTillDepletion == undefined) {
    return;
  }
  if (daysTillDepletion < 1) {
    return 1;
  }
  const leadDepletionRatio = daysTillDepletion / leadTimeDays;

  if (leadDepletionRatio < 0.25) {
    return 1;
  } else if (leadDepletionRatio < 0.75) {
    return 2;
  } else if (leadDepletionRatio < 1.25) {
    return 3;
  } else if (leadDepletionRatio < 2) {
    return 4;
  } else if (leadDepletionRatio < 4) {
    return 5;
  } else {
    return 6;
  }
};
