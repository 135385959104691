import * as React from 'react';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';

interface StockSalesTrendIconProps {
  averageSalesPerDay7d: number | null;
  averageSalesPerDay30d: number | null;
}

export function StockSalesTrendIcon({
  averageSalesPerDay7d,
  averageSalesPerDay30d,
}: StockSalesTrendIconProps) {
  const { t } = useTranslation();

  if (
    (!averageSalesPerDay7d || averageSalesPerDay7d === 0) &&
    (!averageSalesPerDay30d || averageSalesPerDay30d === 0)
  ) {
    return null;
  }

  if (averageSalesPerDay7d! > averageSalesPerDay30d!) {
    return (
      <Tooltip
        enterDelay={200}
        enterNextDelay={200}
        title={t(tokens.automator.stock.sales_trend_up_tooltip)}
      >
        <ArrowCircleUpRoundedIcon
          color="success"
          style={{ fontSize: 32 }}
        />
      </Tooltip>
    );
  } else if (averageSalesPerDay7d! < averageSalesPerDay30d!) {
    return (
      <Tooltip
        enterDelay={200}
        enterNextDelay={200}
        title={t(tokens.automator.stock.sales_trend_down_tooltip)}
      >
        <ArrowCircleDownRoundedIcon
          color="error"
          style={{ fontSize: 32 }}
        />
      </Tooltip>
    );
  } else {
    return (
      <RemoveCircleOutlineIcon
        color="primary"
        style={{ fontSize: 32 }}
      />
    );
  }
}
