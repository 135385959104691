import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InventoryItemDaysAverageState {
  globalDays: number;
  itemDays: Record<string, number>;
}

const initialState: InventoryItemDaysAverageState = {
  globalDays: 30,
  itemDays: {},
};

export const inventoryItemDaysAverageSlice = createSlice({
  name: 'inventoryItemDaysAverage',
  initialState,
  reducers: {
    setGlobalDays: (state, action: PayloadAction<number>) => {
      state.globalDays = action.payload;
      if (action.payload !== null) {
        state.itemDays = {};
      }
    },
    setItemDays: (state, action: PayloadAction<{ itemId: number; days: number }>) => {
      const { itemId, days } = action.payload;
      state.itemDays[itemId] = days;
    },
  },
});

export const { setGlobalDays, setItemDays } = inventoryItemDaysAverageSlice.actions;

export const inventoryItemDaysAverageReducer = inventoryItemDaysAverageSlice.reducer;
