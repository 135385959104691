import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useFetchSupplyChainPhases } from '../../../../api/automator/stock/useFetchSupplyChainPhases';
import { TableLoading } from '../../../../components/TableLoading';
import { tokens } from '../../../../locales/translationTokens';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';
import { useFetchStockSettings } from '../../../../api/automator/stock/useFetchStockSettings';
import { SupplyChainPhaseSettings } from './SupplyChainPhaseSettings';

export const StockSettingsView = () => {
  const { data: supplyChainPhases, isLoading: isLoadingSupplyChainPhases } =
    useFetchSupplyChainPhases();

  const { data: stockSettings, isLoading: isLoadingStockSettings } = useFetchStockSettings();

  const { t } = useTranslation();

  if (isLoadingSupplyChainPhases || isLoadingStockSettings) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  return (
    <Stack
      direction="row"
      gap={2}
      sx={{ width: '100%' }}
    >
      <SupplyChainPhaseSettings
        type={SupplyChainPhaseType.MANUFACTURING}
        supplyChainPhases={supplyChainPhases!}
        stockSettings={stockSettings!}
      />

      <SupplyChainPhaseSettings
        type={SupplyChainPhaseType.TRANSPORT}
        supplyChainPhases={supplyChainPhases!}
        stockSettings={stockSettings!}
      />
    </Stack>
  );
};
