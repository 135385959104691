import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store';
import { setGlobalDays } from './inventoryItemDaysAverageSlice';

export const useGlobalInventoryItemDaysAverage = () => {
  const dispatch = useDispatch();

  // Select globalDays from store
  const globalDays = useSelector(
    (state: RootState) => state.inventory_item_days_average.globalDays
  ) as 7 | 14 | 30;

  // Create a setter function that dispatches the slice action
  const updateGlobalDays = useCallback(
    (days: 7 | 14 | 30) => {
      dispatch(setGlobalDays(days));
    },
    [dispatch]
  );

  return { globalDays, updateGlobalDays };
};
