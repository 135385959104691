import { Build } from '@mui/icons-material';
import ActionButton from '../../../../components/ActionButton';
import { useReturnItemAwaitingRepair } from '../../../../api/automator/returns/useReturnItemAwaitingRepair';
import { ReturnItemStatus } from '../../../../domain/automator/returns/ReturnItemStatus';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface AwaitingRepairActionButtonProps {
  returnItemId: number;
  currentStatus: ReturnItemStatus;
  onSuccess?: () => void;
  showLabel?: boolean;
}

const AwaitingRepairActionButton = ({
  returnItemId,
  currentStatus,
  onSuccess,
  showLabel,
}: AwaitingRepairActionButtonProps) => {
  const { t } = useTranslation();
  const { mutate: returnItemAwaitingRepair, isLoading: returnItemAwaitingRepairIsLoading } =
    useReturnItemAwaitingRepair(returnItemId);

  const handleClick = () => {
    returnItemAwaitingRepair(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.returns.actions.set_to_awaiting_repair));
        onSuccess?.();
      },
    });
  };

  return (
    <ActionButton
      icon={<Build />}
      label={showLabel ? t(tokens.automator.returns.actions.set_to_awaiting_repair) : ''}
      onClick={handleClick}
      isDisabled={
        currentStatus === ReturnItemStatus.REPAIR || currentStatus === ReturnItemStatus.EXCHANGE
      }
      isLoading={returnItemAwaitingRepairIsLoading}
      variant={currentStatus === ReturnItemStatus.REPAIR ? 'contained' : 'outlined'}
      size="small"
    />
  );
};

export default AwaitingRepairActionButton;
