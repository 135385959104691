import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { RestockSignal } from '../../../../domain/automator/stock/RestockSignal';
import LeadTime from '../../../../domain/automator/stock/LeadTime';
import Stack from '@mui/material/Stack';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { getShortenedAlphabeticFormatFromDate } from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';

interface RestockSignalIndicatorProps {
  restockSignal: RestockSignal;
  leadTime: LeadTime;
  daysTillDepletion: number | null;
}

export const RestockSignalIndicator = ({
  restockSignal,
  daysTillDepletion,
  leadTime,
}: RestockSignalIndicatorProps) => {
  const { t } = useTranslation();

  const today = new Date();

  const restockDate = daysTillDepletion
    ? new Date(
        today.setDate(
          today.getDate() +
            (daysTillDepletion -
              (leadTime.transportDurationInDays + leadTime.manufacturingDurationInDays))
        )
      )
    : null;

  const determineLabel = (restockSignal: RestockSignal) => {
    switch (restockSignal) {
      case RestockSignal.BUY_NOW:
        return tokens.automator.stock.restock_signal.buy_now;
      case RestockSignal.TOO_LATE:
        return tokens.automator.stock.restock_signal.too_late_to_restock;
      case RestockSignal.REPLENISHED:
        return tokens.automator.stock.restock_signal.replenished;
      default:
        return '';
    }
  };

  const determineColor = (restockSignal: RestockSignal) => {
    switch (restockSignal) {
      case RestockSignal.BUY_NOW:
        return 'info';
      case RestockSignal.TOO_LATE:
        return 'warning';
      case RestockSignal.REPLENISHED:
        return 'success';
      default:
        return 'default';
    }
  };

  return (
    <Stack
      direction="column"
      gap={0.25}
    >
      <Stack
        direction="row"
        gap={0.5}
      >
        <AvTimerIcon />

        <Typography noWrap>
          {restockDate ? getShortenedAlphabeticFormatFromDate(restockDate, false, true) : '-'}
        </Typography>

        {restockSignal != RestockSignal.SUFFICIENT_STOCK && (
          <Chip
            size="small"
            color={determineColor(restockSignal)}
            label={t(determineLabel(restockSignal))}
          />
        )}
      </Stack>

      <Stack
        direction="row"
        gap={0.5}
      >
        <HouseSidingIcon />
        <Typography
          noWrap
          variant="body2"
        >
          {leadTime.transportDurationInDays + leadTime.manufacturingDurationInDays}
          {' ' + t(tokens.automator.stock.settings.supply_chain_phases.days)}
        </Typography>
      </Stack>
    </Stack>
  );
};
