import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useFetchInventoryItemNote } from '../../../../api/automator/stock/useFetchInventoryItemNote';
import { useUpdateInventoryItemNote } from '../../../../api/automator/stock/useUpdateInventoryItemNote';

interface UpdateInventoryItemNoteFormProps {
  onClick?: (note: string) => void;
  inventoryItemId: number;
}

export const UpdateInventoryItemNoteForm = ({
  onClick,
  inventoryItemId,
}: UpdateInventoryItemNoteFormProps) => {
  const { data: inventoryItemNote, isLoading: isLoadingNote } =
    useFetchInventoryItemNote(inventoryItemId);
  const [note, setNote] = useState<string | null>(null);

  useEffect(() => {
    if (inventoryItemNote && note == null) {
      setNote(inventoryItemNote.note);
    }
  }, [inventoryItemNote]);

  const { t } = useTranslation();

  const inventoryItemNoteId = inventoryItemNote?.id ?? 0;

  const { mutate: update, isLoading } = useUpdateInventoryItemNote(inventoryItemNoteId);

  const onUpdate = () => {
    update(
      {
        note: note!,
      },
      {
        onSuccess: async () => {
          onClick?.(note!);
          toast.success(t(tokens.automator.stock.inventory_item_note_dialog.note_updated));
        },
      }
    );
  };

  if (isLoadingNote || note == null || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onBlur={onUpdate}
      style={{ margin: 2 }}
    >
      <TextField
        id="note-field"
        label={t(tokens.automator.stock.inventory_item_note_dialog.note)}
        variant="filled"
        multiline
        rows={4}
        fullWidth
        style={{ marginBottom: '1em', padding: '1em' }}
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
    </form>
  );
};
