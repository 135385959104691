import { SALES_VARIANTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Restock from '../../../domain/automator/stock/Restock';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';

export const useUpdateSalesVariant = (salesVariantId: number) => {
  const url = useAutomatorApiAccountUrl(SALES_VARIANTS_PATH + '/' + salesVariantId);

  return useAuthorizedPost<Restock, UpdateSalesVariantData>(url, [InventoryItemsQueryKey()]);
};

export interface UpdateSalesVariantData {
  sales_unit: number;
}
