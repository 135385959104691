import { INVENTORY_ITEMS } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import { InventoryStocksQueryKey } from '../../queryKeys/InventoryStocksQueryKey';
import { InventoryItemStockAdjustmentsQueryKey } from '../../queryKeys/InventoryItemStockAdjustmentsQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';
import { InventoryItemSuppliersQueryKey } from '../../queryKeys/InventoryItemSuppliersQueryKey';
import { InventoryItemRestockItemsQueryKey } from '../../queryKeys/InventoryItemRestockItemsQueryKey';

export const useAddSalesVariantToInventoryItem = (inventoryItemId?: number) => {
  const url = useAutomatorApiAccountUrl(
    INVENTORY_ITEMS + '/' + inventoryItemId + '/add-sales-variant'
  );

  return useAuthorizedPost<undefined, AddSalesVariantToInventoryItemData>(url, [
    InventoryItemsQueryKey(),
    InventoryStocksQueryKey(inventoryItemId),
    InventoryItemStockAdjustmentsQueryKey(inventoryItemId),
    InventoryItemInsightQueryKey(inventoryItemId),
    InventoryItemSuppliersQueryKey(inventoryItemId),
    InventoryItemRestockItemsQueryKey(inventoryItemId),
    InventoryItemInsightQueryKey(inventoryItemId),
  ]);
};

export interface AddSalesVariantToInventoryItemData {
  sales_variant_id: number;
  stock_to_move?: number;
}
