import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import { RemoveSalesVariantFromInventoryItemForm } from '../components/RemoveSalesVariantFromInventoryItemForm';
import SalesVariant from '../../../../domain/automator/stock/SalesVariant';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';

export const RemoveSalesVariantFromInventoryItemDialog = () => {
  const { isOpen, data } = useIsDialogOpen(DialogId.REMOVE_SALES_VARIANT_FROM_INVENTORY_ITEM);
  const { t } = useTranslation();

  const closeDialog = useCloseDialog(DialogId.REMOVE_SALES_VARIANT_FROM_INVENTORY_ITEM);

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={DialogId.REMOVE_SALES_VARIANT_FROM_INVENTORY_ITEM}
          title={t(tokens.automator.stock.remove_sales_variant_dialog.remove_sales_variant)}
        >
          <RemoveSalesVariantFromInventoryItemForm
            salesVariant={data.salesVariant}
            inventoryItem={data.inventoryItem}
            onClick={() => closeDialog()}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface RemoveSalesVariantFromInventoryItemDialogData {
  inventoryItem: InventoryItem;
  salesVariant: SalesVariant;
}
