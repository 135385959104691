import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { RestockForm } from './RestockForm';
import { toast } from 'react-hot-toast';
import { useCreateRestock } from '../../../../api/automator/stock/useCreateRestock';
import { EditRestockItem, RestockItemMetricOption } from './RestockItemForm';
import ReducedInventoryItem from '../../../../domain/automator/stock/ReducedInventoryItem';

export const CreateRestockDialog = () => {
  const id = DialogId.CREATE_RESTOCK;

  const { mutate: doCreateRestock } = useCreateRestock();

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  const onSave = (items: EditRestockItem[]) => {
    doCreateRestock(
      {
        items: items
          .filter((item) => item.quantity > 0)
          .map((item) => ({
            inventory_item_id: item.inventoryItem.id,
            supplier_id: item.supplierId,
            quantity: item.quantity,
            unit_price_in_cents:
              item.metricOption == RestockItemMetricOption.TOTAL && item.unitPriceInCents
                ? item.unitPriceInCents / item.quantity
                : item.unitPriceInCents,
            unit_transport_cost_in_cents:
              item.metricOption == RestockItemMetricOption.TOTAL && item.unitTransportCostInCents
                ? item.unitTransportCostInCents / item.quantity
                : item.unitTransportCostInCents,
            total_cubic_meters:
              item.metricOption == RestockItemMetricOption.TOTAL && item.totalCubicMeters
                ? item.totalCubicMeters / item.quantity
                : item.totalCubicMeters,
            total_weight_in_kg:
              item.metricOption == RestockItemMetricOption.TOTAL && item.totalWeightInKg
                ? item.totalWeightInKg / item.quantity
                : item.totalWeightInKg,
            expected_delivery_date: new Date(
              new Date().getTime() + item.daysUntilDelivery * 24 * 60 * 60 * 1000
            ).toISOString(),
          })),
      },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.stock.restock_dialog.restock_created));
          data?.onCreate?.();
        },
      }
    );
    closeDialog();
  };

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'md'}
          title={t(tokens.automator.stock.restock_dialog.restock)}
        >
          <RestockForm
            onSave={onSave}
            supplierId={data!.supplierId}
            inventoryItem={data!.inventoryItem}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface CreateRestockData {
  supplierId: number | null;
  inventoryItem?: ReducedInventoryItem;
  onCreate?: () => void;
}
