import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { UpdateProductGroupSupplyChainPhaseForm } from '../components/UpdateProductGroupSupplyChainPhaseForm';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';

export const UpdateProductGroupSupplyChainPhaseDialog = () => {
  const id = DialogId.UPDATE_PRODUCT_GROUP_SUPPLY_CHAIN_PHASE;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.stock.supply_chain_phase_dialog.update_supply_chain_phase)}
        >
          <UpdateProductGroupSupplyChainPhaseForm
            type={data.type}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateProductGroupSupplyChainPhaseDialogData {
  type: SupplyChainPhaseType;
}
