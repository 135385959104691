import { INVENTORY_ITEMS } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import InventoryItemNote from '../../../domain/automator/products/InventoryItemNote';
import { useFetchData } from '../../useFetchData';
import { InventoryItemNoteQueryKey } from '../../queryKeys/InventoryItemNoteQueryKey';

export const useFetchInventoryItemNote = (inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(`${INVENTORY_ITEMS}/${inventoryItemId}/note`);
  return useFetchData<InventoryItemNote>(url, [InventoryItemNoteQueryKey(inventoryItemId)]);
};
