import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { InventoryStocksQueryKey } from '../../queryKeys/InventoryStocksQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';

export const useCreateStocktake = (inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(INVENTORY_ITEMS + `/${inventoryItemId}/stocktake`);
  return useAuthorizedPost<undefined, CreateStocktakeData>(url, [
    InventoryItemsQueryKey(),
    InventoryStocksQueryKey(inventoryItemId),
    InventoryItemInsightQueryKey(),
  ]);
};

export interface CreateStocktakeData {
  quantity: number;
}
