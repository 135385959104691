import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import AutomatorProduct from '../../../../domain/automator/products/AutomatorProduct';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ProductGroupChip } from './ProductGroupChip';
import { useFetchProductGroups } from '../../../../api/automator/products/useFetchProductGroups';

interface AddProductGroupToProductListProps {
  product: AutomatorProduct;
  onClick: (productGroupId: number) => void;
}

export const AddProductGroupToProductList = ({
  product,
  onClick,
}: AddProductGroupToProductListProps) => {
  const { data: productGroups, isLoading: isLoadingProductGroups } = useFetchProductGroups();

  const { t } = useTranslation();

  if (isLoadingProductGroups) {
    return <Typography>{t(tokens.common.loading)}</Typography>;
  }

  const currentProductGroupIds = product.productGroupIds;

  const filteredProductGroups = productGroups!.filter(
    (productGroup) => !currentProductGroupIds.includes(productGroup.id)
  );

  return (
    <List sx={{ backgroundColor: 'transparent' }}>
      {filteredProductGroups.map((group) => (
        <ListItem
          key={group.id}
          disablePadding
          sx={{ mb: 1 }}
        >
          <ProductGroupChip
            key={group.id}
            productGroup={group}
            onClick={() => onClick(group.id)}
          />
        </ListItem>
      ))}
    </List>
  );
};
