import ActionButton from '../../../../components/ActionButton';
import { useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import { useCreateStocktake } from '../../../../api/automator/stock/useCreateStocktake';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import Typography from '@mui/material/Typography';

interface StocktakeFormProps {
  onClick?: () => void;
  inventoryItem: InventoryItem;
}

export const StocktakeForm = ({ inventoryItem, onClick }: StocktakeFormProps) => {
  const [quantity, setQuantity] = useState<number | null>(null);

  const { t } = useTranslation();
  const { mutate: createStocktake, isLoading } = useCreateStocktake(inventoryItem.id);

  const onSave = () => {
    if (quantity === null) {
      return;
    }

    createStocktake(
      { quantity: quantity },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.stock.stocktake_dialog.stocktake_created));
        },
      }
    );
  };

  return (
    <Stack>
      <Stack
        direction="row"
        gap={2}
      >
        <TooltippedProductThumbnail
          thumbnailUrl={inventoryItem.salesVariants[0].thumbnailUrl}
          productTitle={inventoryItem.salesVariants[0].title}
          quantity={inventoryItem.currentStock}
          height={100}
        />

        <Box width={300}>
          <TextField
            helperText={t(
              tokens.automator.stock.stocktake_dialog.total_amount_in_stock_explanation
            )}
            label={t(tokens.automator.stock.stocktake_dialog.total_amount_in_stock)}
            variant="outlined"
            value={quantity !== null ? quantity : ''}
            onChange={(e) => {
              const inputValue = e.target.value.trim();
              setQuantity(inputValue === '' ? null : parseInt(inputValue, 10));
            }}
          />
        </Box>

        {quantity && quantity != 0 && (
          <Typography>
            {t(tokens.automator.stock.stocktake_dialog.corrected_amount)}{' '}
            {quantity! - inventoryItem.currentStock}
          </Typography>
        )}
      </Stack>

      <ActionButton
        label={t(tokens.common.save)}
        onClick={onSave}
        isLoading={isLoading}
      />
    </Stack>
  );
};
