import Stack from '@mui/material/Stack';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import SalesVariant from '../../../../domain/automator/stock/SalesVariant';
import { truncateString } from '../../../../helpers/truncateString';
import { Box } from '@mui/system';
import Badge from '@mui/material/Badge';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { ProductPageIcon } from '../../orders/ProductPageIcon';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';

interface SalesVariantInfoProps {
  salesVariant: SalesVariant;
  hasSiblingVariant: boolean;
  date: string | null;
}

export const SalesVariantInfo = ({
  salesVariant,
  hasSiblingVariant,
  date,
}: SalesVariantInfoProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap={1.5}
      padding={1}
      alignItems="top"
      minWidth={300}
    >
      <TooltippedProductThumbnail
        thumbnailUrl={salesVariant.thumbnailUrl}
        productTitle={salesVariant.title}
        height={60}
      />

      <Stack
        direction="column"
        gap={0.25}
      >
        {date && (
          <Stack
            direction="row"
            gap={0.5}
          >
            <ArrowCircleRightIcon color="success" />

            <Typography>
              {getShortendAlphabeticFormatFromDate(new Date(date!), false, true)}
            </Typography>
          </Stack>
        )}

        <Badge
          badgeContent={t(tokens.automator.stock.sales_variant_table.has_sibling_variant)}
          color="primary"
          invisible={!hasSiblingVariant}
        >
          <Box style={{ border: '0.5px solid black', borderRadius: '16px', padding: '4px 16px' }}>
            <Stack direction="column">
              <Typography
                variant="body1"
                color="textPrimary"
                fontWeight="bold"
              >
                {salesVariant.internalReference || truncateString(salesVariant.title, 20)}
              </Typography>

              <Stack
                direction="row"
                gap={0.2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  {hasSiblingVariant
                    ? t(tokens.automator.stock.sales_variant_table.multiple_eans)
                    : salesVariant.ean}
                </Typography>
                <ProductPageIcon
                  iconSize={16}
                  productPageUrl={salesVariant.productPageUrl}
                />
              </Stack>
            </Stack>
          </Box>
        </Badge>
      </Stack>
    </Stack>
  );
};
