import ReturnItem from '../../../../domain/automator/returns/ReturnItem';
import { useTranslation } from 'react-i18next';
import { useRejectReturnItem } from '../../../../api/automator/returns/useRejectReturnItem';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import { ThumbDown } from '@mui/icons-material';
import ActionButton from '../../../../components/ActionButton';

interface RejectReturnItemButtonProps {
  returnItem: ReturnItem;
  quantityReturned: number;
  onClick?: () => void;
}

const RejectReturnItemButton = ({
  returnItem,
  quantityReturned,
  onClick,
}: RejectReturnItemButtonProps) => {
  const { t } = useTranslation();
  const { mutate: rejectReturnItem, isLoading } = useRejectReturnItem(returnItem.id);

  const onSave = () => {
    rejectReturnItem(
      { quantity_returned: quantityReturned },
      {
        onSuccess: async () => {
          toast.success(t(tokens.automator.returns.actions.return_item_rejected));
          onClick && onClick();
        },
      }
    );
  };

  return (
    <ActionButton
      style={{ backgroundColor: '#F04438' }}
      icon={<ThumbDown style={{ color: 'WHITE' }} />}
      label={t(tokens.automator.returns.actions.reject)}
      onClick={() => onSave()}
      isLoading={isLoading}
    />
  );
};

export default RejectReturnItemButton;
