import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { AnyAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { enableDevTools } from 'devias/src/config';
import { authReducer } from './auth/authSlice';
import { combineReducers, Reducer } from 'redux';
import { dialogReducer } from './dialogs/dialogsSlice';
import { copyButtonsReducer } from './activeCopyButton/activeCopyButtonsSlice';
import { orderTabsReducer } from './orderTabs/orderTabsSlice';
import { emailMessageDraftSlice } from './emailMessageDrafts/emailMessageDraftSlice';
import { inventoryItemDaysAverageReducer } from './inventoryItemDaysAverage/inventoryItemDaysAverageSlice';

const appReducer = combineReducers({
  auth: authReducer,
  dialogs: dialogReducer,
  active_copy_button: copyButtonsReducer,
  orders_tabs: orderTabsReducer,
  email_message_drafts: emailMessageDraftSlice.reducer,
  inventory_item_days_average: inventoryItemDaysAverageReducer,
});

export const rootReducer: Reducer<RootState, AnyAction> = (
  state: RootState | undefined,
  action: AnyAction
): RootState => {
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: enableDevTools,
});

export type RootState = ReturnType<typeof appReducer>;

export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
