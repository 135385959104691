import { RESTOCKS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';
import { RestockItemStatus } from '../../../domain/automator/stock/RestockItemStatus';
import Restock from '../../../domain/automator/stock/Restock';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import { InventoryStocksQueryKey } from '../../queryKeys/InventoryStocksQueryKey';
import { InventoryItemStockAdjustmentsQueryKey } from '../../queryKeys/InventoryItemStockAdjustmentsQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';
import { InventoryItemSuppliersQueryKey } from '../../queryKeys/InventoryItemSuppliersQueryKey';
import { RestockQueryKey } from '../../queryKeys/RestockQueryKey';

export const useEditRestock = (restockId: number) => {
  const url = useAutomatorApiAccountUrl(RESTOCKS_PATH + '/' + restockId);

  return useAuthorizedPost<Restock, EditRestockData>(url, [
    InventoryItemsQueryKey(),
    RestocksQueryKey(RestockItemStatus.DELIVERED),
    RestocksQueryKey(RestockItemStatus.ORDERED),
    RestockQueryKey(),

    InventoryStocksQueryKey(),
    InventoryItemStockAdjustmentsQueryKey(),
    InventoryItemInsightQueryKey(),
    InventoryItemSuppliersQueryKey(),
  ]);
};

export interface EditRestockData {
  expected_delivery_date: string;
}
