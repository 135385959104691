import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import SupplyChainPhase from '../../../../domain/automator/stock/SupplyChainPhase';
import ActionButton from '../../../../components/ActionButton';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import Stack from '@mui/material/Stack';

interface SupplyChainPhaseTableRowProps {
  supplyChainPhase: SupplyChainPhase;
}

export const SupplyChainPhaseTableRow = ({ supplyChainPhase }: SupplyChainPhaseTableRowProps) => {
  const { t } = useTranslation();

  const openUpdateDialog = useOpenDialog(DialogId.UPDATE_SUPPLY_CHAIN_PHASE);

  return (
    <TableRow>
      <TableCell>
        <Typography>{supplyChainPhase.name}</Typography>
      </TableCell>

      <TableCell>
        <Typography>
          {supplyChainPhase.durationInDays +
            ' ' +
            t(tokens.automator.stock.settings.supply_chain_phases.days)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography>
          {supplyChainPhase.amountOfInventoryItems +
            ' ' +
            t(tokens.automator.stock.settings.supply_chain_phases.amount_of_inventory_items)}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <Stack
          direction="row"
          gap={0.5}
          textAlign="right"
        >
          <ActionButton
            onClick={() => openUpdateDialog({ supplyChainPhase })}
            label={t(tokens.automator.stock.supply_chain_phase_dialog.update_supply_chain_phase)}
            variant="outlined"
            color="primary"
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
