import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { RESTOCKS_PATH } from '../automatorApiPaths';
import Restock from '../../../domain/automator/stock/Restock';
import { RestockQueryKey } from '../../queryKeys/RestockQueryKey';

export const useFetchRestock = (id: number) => {
  const url = useAutomatorApiAccountUrl(RESTOCKS_PATH + `/${id}`);
  return useFetchData<Restock>(url, RestockQueryKey(id));
};
