import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { UpdateSupplyChainPhaseForm } from '../components/UpdateSupplyChainPhaseForm';
import SupplyChainPhase from '../../../../domain/automator/stock/SupplyChainPhase';

export const UpdateSupplyChainPhaseDialog = () => {
  const id = DialogId.UPDATE_SUPPLY_CHAIN_PHASE;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.stock.supply_chain_phase_dialog.update_supply_chain_phase)}
        >
          <UpdateSupplyChainPhaseForm
            supplyChainPhase={data.supplyChainPhase}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateSupplyChainPhaseDialogData {
  supplyChainPhase: SupplyChainPhase;
}
