import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { STOCK_SETTINGS } from '../automatorApiPaths';
import StockSettings from '../../../domain/automator/stock/StockSettings';
import { StockSettingsQueryKey } from '../../queryKeys/StockSettingsQueryKey';

export const useFetchStockSettings = () => {
  const url = useAutomatorApiAccountUrl(STOCK_SETTINGS);
  return useFetchData<StockSettings>(url, StockSettingsQueryKey());
};
