import { Aggregate } from '../../Aggregate';

export default interface InventoryItemInsight extends Aggregate {
  productId: number;
  startDate: string;
  endDate: string;

  items: InventoryItemInsightItem[];

  recommendedRestockDate7d: string | null;
  recommendedRestockDate14d: string | null;
  recommendedRestockDate30d: string | null;

  depletionDate7d: string | null;
  depletionDate14d: string | null;
  depletionDate30d: string | null;
}

export interface InventoryItemInsightItem {
  date: string | null;
  quantity: number;
  type: InventoryItemInsightItemType;
}

export enum InventoryItemInsightItemType {
  CURRENT = 'CURRENT',
  PERIOD_START = 'PERIOD_START',
  RESTOCK = 'RESTOCK',
  SALES = 'SALES',
  RETURNS = 'RETURNS',
}
