import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import { useFetchSupplyChainPhases } from '../../../../api/automator/stock/useFetchSupplyChainPhases';
import { useTranslation } from 'react-i18next';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';

interface SupplyChainPhaseDropdownProps {
  type: SupplyChainPhaseType;
  selected: number | null;
  onSelect: (supplyChainPhaseId: number) => void;
  isLoading?: boolean;
}

export const SupplyChainPhaseDropdown = ({
  type,
  onSelect,
  selected,
  isLoading,
}: SupplyChainPhaseDropdownProps) => {
  const { data: supplyChainPhases, isLoading: isLoadingFetch } = useFetchSupplyChainPhases();

  const { t } = useTranslation();

  if (isLoading || isLoadingFetch) {
    return <div>Loading...</div>;
  }

  const options = supplyChainPhases!
    .filter((phase) => phase.type == type)
    .map((supplyChainPhase) => ({
      key: supplyChainPhase.id,
      value: supplyChainPhase.name,
    }));

  return (
    <TextField
      label={t(tokens.automator.stock.settings.supply_chain_phases.supply_chain_phase)}
      select
      value={selected}
      SelectProps={{ native: true }}
      onChange={(e) => onSelect(e.target.value as unknown as number)}
    >
      {options.map((option) => (
        <option
          key={option.key}
          value={option.key}
        >
          {option.value}
        </option>
      ))}
    </TextField>
  );
};
