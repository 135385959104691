import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import Suppliers from '../../../domain/automator/stock/Suppliers';
import { InventoryItemSuppliersQueryKey } from '../../queryKeys/InventoryItemSuppliersQueryKey';

export const useFetchInventoryItemSuppliers = (inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(INVENTORY_ITEMS + `/${inventoryItemId}/suppliers`);
  return useFetchData<Suppliers>(url, InventoryItemSuppliersQueryKey(inventoryItemId));
};
