import { INVENTORY_ITEMS } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { InventoryItemSuppliersQueryKey } from '../../queryKeys/InventoryItemSuppliersQueryKey';

export const useAddSupplierToInventoryItem = (inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(INVENTORY_ITEMS + `/${inventoryItemId}/suppliers/add`);
  return useAuthorizedPost<undefined, AddSupplierToInventoryItemData>(url, [
    InventoryItemSuppliersQueryKey(inventoryItemId),
  ]);
};

export interface AddSupplierToInventoryItemData {
  supplier_id: number;
}
