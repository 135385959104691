import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { RESTOCKS_PATH } from '../automatorApiPaths';
import { RestockItemStatus } from '../../../domain/automator/stock/RestockItemStatus';
import Restock from '../../../domain/automator/stock/Restock';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';

export const useFetchRestocks = (status: RestockItemStatus) => {
  const url = useAutomatorApiAccountUrl(RESTOCKS_PATH + `?status=${status}`);
  return useFetchData<Restock[]>(url, RestocksQueryKey(status));
};
