import { EndpointQueryKey } from './EndpointQueryKey';
import { InventoryItemsSort } from '../automator/stock/useFetchInventoryItems';
import { SortDirection } from '../page/SortDirection';

export const InventoryItemsQueryKey = (
  page?: number,
  size?: number,
  sort?: InventoryItemsSort,
  direction?: SortDirection,
  productGroupId?: number,
  query?: string
) => {
  if (page === undefined || size === undefined) {
    return [EndpointQueryKey.INVENTORY_ITEMS];
  }

  return [EndpointQueryKey.INVENTORY_ITEMS, page, size, sort, direction, productGroupId, query];
};
