import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export enum StockPeriod {
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  THREE_WEEKS = 'THREE_WEEKS',
  ONE_MONTH = 'ONE_MONTH',
  TWO_MONTHS = 'TWO_MONTHS',
  THREE_MONTHS = 'THREE_MONTHS',
  FOUR_MONTHS = 'FOUR_MONTHS',
  FIVE_MONTHS = 'FIVE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  SEVEN_MONTHS = 'SEVEN_MONTHS',
  EIGHT_MONTHS = 'EIGHT_MONTHS',
  NINE_MONTHS = 'NINE_MONTHS',
  TEN_MONTHS = 'TEN_MONTHS',
}

export interface StockPeriodOption {
  key: StockPeriod;
  value: string;
  days: number;
}

interface StockPeriodDropdownProps {
  selected: StockPeriodOption | null;
  onSelect: (option: StockPeriodOption) => void;
}

export const StockPeriodDropdown = ({ onSelect, selected }: StockPeriodDropdownProps) => {
  const { t } = useTranslation();

  const options: StockPeriodOption[] = [
    {
      key: StockPeriod.ONE_WEEK,
      value: t(tokens.automator.stock.stock_period.one_week),
      days: 7,
    },
    {
      key: StockPeriod.TWO_WEEKS,
      value: t(tokens.automator.stock.stock_period.two_weeks),
      days: 14,
    },
    {
      key: StockPeriod.THREE_WEEKS,
      value: t(tokens.automator.stock.stock_period.three_weeks),
      days: 21,
    },
    {
      key: StockPeriod.ONE_MONTH,
      value: t(tokens.automator.stock.stock_period.one_month),
      days: 30,
    },
    {
      key: StockPeriod.TWO_MONTHS,
      value: t(tokens.automator.stock.stock_period.two_months),
      days: 60,
    },
    {
      key: StockPeriod.THREE_MONTHS,
      value: t(tokens.automator.stock.stock_period.three_months),
      days: 90,
    },
    {
      key: StockPeriod.FOUR_MONTHS,
      value: t(tokens.automator.stock.stock_period.four_months),
      days: 120,
    },
    {
      key: StockPeriod.FIVE_MONTHS,
      value: t(tokens.automator.stock.stock_period.five_months),
      days: 150,
    },
    {
      key: StockPeriod.SIX_MONTHS,
      value: t(tokens.automator.stock.stock_period.six_months),
      days: 180,
    },
    {
      key: StockPeriod.SEVEN_MONTHS,
      value: t(tokens.automator.stock.stock_period.seven_months),
      days: 210,
    },
    {
      key: StockPeriod.EIGHT_MONTHS,
      value: t(tokens.automator.stock.stock_period.eight_months),
      days: 240,
    },
    {
      key: StockPeriod.NINE_MONTHS,
      value: t(tokens.automator.stock.stock_period.nine_months),
      days: 270,
    },
    {
      key: StockPeriod.TEN_MONTHS,
      value: t(tokens.automator.stock.stock_period.ten_months),
      days: 300,
    },
  ];

  useEffect(() => {
    if (selected == null) {
      onSelect(options[0]);
    }
  }, [selected]);

  return (
    <TextField
      fullWidth
      label={t(tokens.automator.stock.stock_period.stock_period)}
      select
      size="small"
      value={selected?.key}
      SelectProps={{ native: true }}
      onChange={(e) => {
        onSelect(options.find((option) => option.key == (e.target.value as StockPeriod))!);
      }}
    >
      {options.map((option) => (
        <option
          key={option.key}
          value={option.key}
        >
          {option.value}
        </option>
      ))}
    </TextField>
  );
};
