import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import { SupplyChainPhasesQueryKey } from '../../queryKeys/SupplyChainPhasesQueryKey';

export const useBulkUpdateInventoryItemLeadTime = () => {
  const url = useAutomatorApiAccountUrl(INVENTORY_ITEMS + `/bulk-update-lead-time`);
  return useAuthorizedPost<undefined, BulkUpdateInventoryItemLeadTimeData>(url, [
    InventoryItemsQueryKey(),
    SupplyChainPhasesQueryKey(),
  ]);
};

export interface BulkUpdateInventoryItemLeadTimeData {
  product_group_id: number;
  transport_supply_chain_phase_id: number | null;
  manufacturing_supply_chain_phase_id: number | null;
}
