import Stack from '@mui/material/Stack';

import * as React from 'react';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import { InventoryItemInsightTable } from './InventoryItemInsightTable';
import TableRow from '@mui/material/TableRow';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { StockAverageSalesToggleButtons } from './StockAverageSalesToggleButton';
import { StockSalesTrendIcon } from './StockSalesTrendIconProps';
import { getShortenedAlphabeticFormatFromDate } from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import { DepletionLevelChip } from './DepletionLevelChip';
import { useFetchInventoryItemInsight } from '../../../../api/automator/stock/useFetchInventoryItemInsight';
import { useInventoryItemDaysAverage } from '../../../../store/inventoryItemDaysAverage/useInventoryItemDaysAverage';

interface InventoryItemInsightViewProps {
  inventoryItem: InventoryItem;
}

export const InventoryItemInsightView = ({ inventoryItem }: InventoryItemInsightViewProps) => {
  const { t } = useTranslation();

  const { data: inventoryItemInsight, isLoading } = useFetchInventoryItemInsight(inventoryItem.id);

  const { days, updateItemDays } = useInventoryItemDaysAverage(inventoryItem.id);

  const recommendedStockDate = (days: 7 | 14 | 30) => {
    switch (days) {
      case 7:
        return inventoryItemInsight!.recommendedRestockDate7d;
      case 14:
        return inventoryItemInsight!.recommendedRestockDate14d;
      case 30:
        return inventoryItemInsight!.recommendedRestockDate30d;
    }
  };

  const depletionDate = (days: 7 | 14 | 30) => {
    switch (days) {
      case 7:
        return inventoryItemInsight!.depletionDate7d;
      case 14:
        return inventoryItemInsight!.depletionDate14d;
      case 30:
        return inventoryItemInsight!.depletionDate30d;
    }
  };

  const daysTillDepletion =
    days === 7
      ? inventoryItem.daysTillsDepletion7d
      : days === 14
      ? inventoryItem.daysTillsDepletion14d
      : inventoryItem.daysTillsDepletion30d;

  const leadTime =
    inventoryItem.leadTime.transportDurationInDays +
    inventoryItem.leadTime.manufacturingDurationInDays;

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Stack
      direction="row"
      gap={2}
      paddingBottom={2}
    >
      <InventoryItemInsightTable inventoryItem={inventoryItem} />

      <Table>
        <TableRow>
          <SlimTableCell>
            <Typography variant="body2">
              {t(tokens.automator.stock.product_stock_insight.start_restock)}
            </Typography>
          </SlimTableCell>
          <SlimTableCell>
            <Typography variant="body2">
              {recommendedStockDate(days) &&
                getShortenedAlphabeticFormatFromDate(
                  new Date(recommendedStockDate(days)!),
                  false,
                  true
                )}
            </Typography>
          </SlimTableCell>
        </TableRow>

        <TableRow>
          <SlimTableCell>
            <Typography variant="body2">
              {t(tokens.automator.stock.product_stock_insight.depletion_level)}
            </Typography>
          </SlimTableCell>
          <SlimTableCell>
            <DepletionLevelChip
              leadTimeDays={leadTime}
              daysTillDepletion={daysTillDepletion == null ? undefined : daysTillDepletion}
            />
          </SlimTableCell>
        </TableRow>

        <TableRow>
          <SlimTableCell>
            <Typography variant="body2">
              {t(tokens.automator.stock.product_stock_insight.expected_depletion_date)}
            </Typography>
          </SlimTableCell>
          <SlimTableCell>
            <Typography variant="body2">
              {depletionDate(days) &&
                getShortenedAlphabeticFormatFromDate(new Date(depletionDate(days)!), false, true)}
            </Typography>
          </SlimTableCell>
        </TableRow>

        <TableRow>
          <SlimTableCell>
            <Typography variant="body2">
              {t(tokens.automator.stock.product_stock_insight.lead_time)}
            </Typography>
          </SlimTableCell>
          <SlimTableCell>
            <Typography variant="body2">
              {inventoryItem.leadTime.transportDurationInDays +
                inventoryItem.leadTime.manufacturingDurationInDays +
                ' ' +
                t(tokens.automator.stock.settings.supply_chain_phases.days)}
            </Typography>
          </SlimTableCell>
        </TableRow>

        <TableRow>
          <SlimTableCell>
            <Typography variant="body2">
              {t(tokens.automator.stock.product_stock_insight.average_sales_per_day)}
            </Typography>
          </SlimTableCell>
          <SlimTableCell>
            <Stack
              direction="row"
              gap={1}
              alignItems={'center'}
            >
              <StockAverageSalesToggleButtons
                selectedDays={days}
                onChange={(days) => updateItemDays(days)}
                averageSalesPerDay7d={inventoryItem.averageSalesPerDay7d}
                averageSalesPerDay14d={inventoryItem.averageSalesPerDay14d}
                averageSalesPerDay30d={inventoryItem.averageSalesPerDay30d}
              />

              <StockSalesTrendIcon
                averageSalesPerDay7d={inventoryItem.averageSalesPerDay7d}
                averageSalesPerDay30d={inventoryItem.averageSalesPerDay30d}
              />
            </Stack>
          </SlimTableCell>
        </TableRow>
      </Table>
    </Stack>
  );
};
