import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import { SalesVariantTableRow } from './SalesVariantTableRow';
import { AutomatorProductMultiSelect } from '../../autoEmail/components/AutomatorProductMultiSelect';
import { useState } from 'react';
import { AddSalesVariantToInventoryItemActionButton } from './AddSalesVariantToInventoryItemActionButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

interface SalesVariantsListTableProps {
  inventoryItem: InventoryItem;
}

export const SalesVariantsListTable = ({ inventoryItem }: SalesVariantsListTableProps) => {
  const { t } = useTranslation();

  const [productIds, setProductIds] = useState<number[]>([]);

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.stock.sales_variant_table.product)}</TableCell>
            <TableCell>{t(tokens.automator.stock.sales_variant_table.sales_unit)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {inventoryItem
            .salesVariants!.sort((a, b) => a.id - b.id)
            .map((salesVariant) => {
              return (
                <SalesVariantTableRow
                  key={salesVariant.id}
                  inventoryItem={inventoryItem}
                  salesVariant={salesVariant}
                  canRemove={inventoryItem.salesVariants!.length > 1}
                  hasSiblingVariant={inventoryItem.salesVariants!.length > 1}
                />
              );
            })}

          <TableRow>
            <TableCell colSpan={2}>
              <Stack
                direction="row"
                alignItems="center"
                gap={3}
              >
                <Typography
                  variant="body1"
                  color="textPrimary"
                  fontWeight="bold"
                >
                  {t(tokens.automator.stock.sales_variant_table.add_sales_variant_explanation)}
                </Typography>

                <AutomatorProductMultiSelect
                  productIds={productIds}
                  onSelect={setProductIds}
                  multiple={false}
                  titleLength={110}
                />
              </Stack>
            </TableCell>
            <TableCell>
              {productIds.length > 0 && (
                <AddSalesVariantToInventoryItemActionButton
                  onAdd={() => setProductIds([])}
                  inventoryItem={inventoryItem}
                  productId={productIds[0]}
                />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
