import { InventoryItemNoteQueryKey } from '../../queryKeys/InventoryItemNoteQueryKey';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { INVENTORY_ITEM_NOTES } from '../automatorApiPaths';
import InventoryItemNote from '../../../domain/automator/products/InventoryItemNote';

export const useUpdateInventoryItemNote = (inventoryItemNoteId: number) => {
  const url = useAutomatorApiAccountUrl(`${INVENTORY_ITEM_NOTES}/${inventoryItemNoteId}`);
  return useAuthorizedPost<InventoryItemNote, UpdateInventoryItemNoteData>(url, [
    InventoryItemNoteQueryKey(),
  ]);
};

export interface UpdateInventoryItemNoteData {
  note: string;
}
