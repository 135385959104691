import { SUPPLY_CHAIN_PHASES } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Restock from '../../../domain/automator/stock/Restock';
import { SupplyChainPhasesQueryKey } from '../../queryKeys/SupplyChainPhasesQueryKey';

export const useUpdateSupplyChainPhase = (supplyChainPhaseId: number) => {
  const url = useAutomatorApiAccountUrl(SUPPLY_CHAIN_PHASES + `/${supplyChainPhaseId}`);

  return useAuthorizedPost<Restock, UpdateSupplyChainPhaseData>(url, [SupplyChainPhasesQueryKey()]);
};

export interface UpdateSupplyChainPhaseData {
  name: string;
  duration_in_days: number;
}
