import { RETURN_ITEMS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/returns/Returns';
import { ReturnScansQueryKey } from '../../queryKeys/ReturnScansQueryKey';
import { OrderMessageThreadsQueryKey } from '../../queryKeys/OrderMessageThreadsQueryKey';
import { OrderReturnsQueryKey } from '../../queryKeys/OrderReturnsQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';

export const useAcceptReturnItem = (returnItemId: number) => {
  const url = useAutomatorApiAccountUrl(RETURN_ITEMS_PATH + '/' + returnItemId + `/accept`);

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (returns: Returns) => {
    updateData(returns);
  };

  return useAuthorizedPost<Returns, AcceptReturnItemData>(
    url,
    [
      OrderMessageThreadsQueryKey(),
      ReturnScansQueryKey(),
      OrderReturnsQueryKey(),
      InventoryItemInsightQueryKey(),
      InventoryItemsQueryKey(),
      InventoryItemsQueryKey(),
    ],
    undefined,
    onSuccess
  );
};

export interface AcceptReturnItemData {
  quantity_returned: number;
}
