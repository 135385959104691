import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { RestockItemTableRow } from './RestockItemTableRow';
import { useFetchInventoryItemRestockItems } from '../../../../api/automator/stock/useFetchInventoryItemRestockItems';
import { useFetchInventoryStocks } from '../../../../api/automator/stock/useFetchInventoryStocks';
import { InventoryStockType } from '../../../../domain/automator/stock/InventoryStockType';
import { StocktakeInventoryStockTableRow } from './StocktakeInventoryStockTableRow';

interface InventoryStockListTableProps {
  inventoryItemId: number;
}

export const InventoryItemRestockItemsListTable = ({
  inventoryItemId,
}: InventoryStockListTableProps) => {
  const { data: restockItems, isLoading: isLoadingRestockItems } =
    useFetchInventoryItemRestockItems(inventoryItemId);

  const { data: inventoryStocks, isLoading: isLoadingInventoryStocks } =
    useFetchInventoryStocks(inventoryItemId);

  const { t } = useTranslation();

  if (isLoadingRestockItems || isLoadingInventoryStocks) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  const stockTakeInventoryStock = inventoryStocks!.find(
    (inventoryStock) => inventoryStock.type == InventoryStockType.STOCKTAKE
  );

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.stock.product_stock_table.type)}</TableCell>
            <TableCell>{t(tokens.automator.stock.product_stock_table.date)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restock_dialog.quantity)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restock_dialog.unit_price)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restock_dialog.unit_transport_cost)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restock_dialog.volume)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restock_dialog.weight)}</TableCell>
            <TableCell>{t(tokens.automator.stock.product_stock_table.delivery_date)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {restockItems!
            .sort((a, b) => {
              return b.id - a.id;
            })
            .map((restockItem) => {
              return (
                <RestockItemTableRow
                  key={restockItem.id}
                  restockItem={restockItem}
                />
              );
            })}
        </TableBody>

        <StocktakeInventoryStockTableRow inventoryStock={stockTakeInventoryStock!} />
      </Table>
    </Scrollbar>
  );
};
