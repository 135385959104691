import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import { StockAdjustmentType } from '../../../../domain/automator/stock/StockAdjustmentType';
import { useFetchInventoryItemStockAdjustments } from '../../../../api/automator/stock/useFetchInventoryItemStockAdjustments';
import { StockAdjustmentTableRow } from './StockAdjustmentTableRow';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import StockAdjustment from '../../../../domain/automator/stock/StockAdjustment';

interface StockAdjustmentsTableProps {
  inventoryItem: InventoryItem;
  type: StockAdjustmentType;
}

export const InventoryItemStockAdjustmentsListTable = ({
  inventoryItem,
  type,
}: StockAdjustmentsTableProps) => {
  const { data: stockAdjustments, isLoading } = useFetchInventoryItemStockAdjustments(
    inventoryItem.id,
    type
  );

  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (stockAdjustments!.length == 0) {
    return (
      <TableEmpty message={t(tokens.automator.stock.product_stock_summaries_table.no_items)} />
    );
  }

  const groupedByDateStockAdjustments: Record<string, StockAdjustment[]> = stockAdjustments!.reduce(
    (acc: Record<string, StockAdjustment[]>, stockAdjustment: StockAdjustment) => {
      const date = stockAdjustment.date;

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(stockAdjustment);
      return acc;
    },
    {}
  );

  return (
    <Scrollbar>
      <Table size="small">
        <TableBody>
          {Object.entries(groupedByDateStockAdjustments)
            .sort(([dateA], [dateB]) => {
              return new Date(dateB).getTime() - new Date(dateA).getTime();
            })
            .map(([date, adjustments]) => {
              return (
                <StockAdjustmentTableRow
                  inventoryItem={inventoryItem}
                  key={date}
                  stockAdjustments={adjustments}
                />
              );
            })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
