import { useTranslation } from 'react-i18next';
import { TableTabs } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';

interface ProductStockSummaryDetailsTabsProps {
  currentTab: InventoryItemDetailsTabs;
  setTab: (tab: InventoryItemDetailsTabs) => void;
}

export enum InventoryItemDetailsTabs {
  RESTOCKS = 'RESTOCKS',
  SALES = 'SALES',
  RETURNS = 'RETURNS',
  SUPPLIERS = 'SUPPLIERS',
  INVENTORY_ITEM_INSIGHT = 'INVENTORY_ITEM_INSIGHT',
  SETTINGS = 'SETTINGS',
  NOTES = 'NOTES',
}

const InventoryItemDetailsTab = ({ currentTab, setTab }: ProductStockSummaryDetailsTabsProps) => {
  const { t } = useTranslation();

  const tabOptions = [
    {
      label: t(tokens.automator.stock.summary_details.tabs.product_stock_insight),
      value: InventoryItemDetailsTabs.INVENTORY_ITEM_INSIGHT,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.restock),
      value: InventoryItemDetailsTabs.RESTOCKS,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.sales),
      value: InventoryItemDetailsTabs.SALES,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.returns),
      value: InventoryItemDetailsTabs.RETURNS,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.suppliers),
      value: InventoryItemDetailsTabs.SUPPLIERS,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.notes),
      value: InventoryItemDetailsTabs.NOTES,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.sales_variants),
      value: InventoryItemDetailsTabs.SETTINGS,
    },
  ];

  return (
    <TableTabs
      value={currentTab}
      onChange={(tab: InventoryItemDetailsTabs) => {
        setTab(tab);
      }}
      tabOptions={tabOptions}
    />
  );
};

export default InventoryItemDetailsTab;
