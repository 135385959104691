import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import SalesVariant from '../../../../domain/automator/stock/SalesVariant';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { UpdateInventoryItemOfferListingForm } from '../UpdateInventoryItemOfferListingsForm';

export const UpdateInventoryItemOfferListingsDialog = () => {
  const { isOpen, data } = useIsDialogOpen(DialogId.UPDATE_INVENTORY_ITEM_OFFER_LISTINGS);
  const { t } = useTranslation();

  const closeDialog = useCloseDialog(DialogId.UPDATE_INVENTORY_ITEM_OFFER_LISTINGS);

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          maxWidth="lg"
          dialogId={DialogId.UPDATE_INVENTORY_ITEM_OFFER_LISTINGS}
          title={t(tokens.automator.orders.dialogs.update_offer.update_offers)}
        >
          <UpdateInventoryItemOfferListingForm
            salesVariants={data.salesVariants}
            onClick={() => closeDialog()}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateInventoryItemOfferListingsDialogData {
  salesVariants: SalesVariant[];
}
