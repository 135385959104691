import { INVENTORY_ITEMS } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { InventoryItemsQueryKey } from '../../queryKeys/InventoryItemsQueryKey';
import { InventoryStocksQueryKey } from '../../queryKeys/InventoryStocksQueryKey';
import { InventoryItemStockAdjustmentsQueryKey } from '../../queryKeys/InventoryItemStockAdjustmentsQueryKey';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';
import { InventoryItemSuppliersQueryKey } from '../../queryKeys/InventoryItemSuppliersQueryKey';

export const useRemoveSalesVariantFromInventoryItem = (inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(
    INVENTORY_ITEMS + '/' + inventoryItemId + '/remove-sales-variant'
  );

  return useAuthorizedPost<undefined, RemoveSalesVariantToInventoryItemData>(url, [
    InventoryItemsQueryKey(),
    InventoryStocksQueryKey(inventoryItemId),
    InventoryItemStockAdjustmentsQueryKey(inventoryItemId),
    InventoryItemInsightQueryKey(inventoryItemId),
    InventoryItemSuppliersQueryKey(inventoryItemId),
    InventoryItemInsightQueryKey(inventoryItemId),
  ]);
};

export interface RemoveSalesVariantToInventoryItemData {
  sales_variant_id: number;
  stock_to_move: number;
}
