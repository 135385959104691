import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { RecommendedRestockReportGroupTableRow } from './RecommendedRestockReportGroupTableRow';
import { useFetchRecommendedRestockReport } from '../../../../api/automator/stock/useFetchRecommendedRestockReports';
import RecommendedRestockReport from '../../../../domain/automator/stock/RecommendedRestockReport';
import { parseISO } from 'date-fns';

export const RecommendedRestockReportsTable = () => {
  const { t } = useTranslation();
  const { data: recommendedRestockReports, isLoading } = useFetchRecommendedRestockReport();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  function groupByRestockMonthYear(
    reports: RecommendedRestockReport[]
  ): Record<string, { date: Date; reports: RecommendedRestockReport[] }> {
    return reports.reduce(
      (acc, report) => {
        if (report.recommendedRestockDate) {
          const date = parseISO(report.recommendedRestockDate);
          const monthYear = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}`;
          if (!acc[monthYear]) {
            acc[monthYear] = { date, reports: [] };
          }
          acc[monthYear].reports.push(report);
        }
        return acc;
      },
      {} as Record<string, { date: Date; reports: RecommendedRestockReport[] }>
    );
  }

  const groupedReports = groupByRestockMonthYear(recommendedRestockReports || []);

  const sortedGroupKeys = Object.keys(groupedReports).sort((a, b) => {
    return groupedReports[a].date.getTime() - groupedReports[b].date.getTime();
  });

  return (
    <Scrollbar>
      <Table size="small">
        <TableBody>
          {sortedGroupKeys.map((monthYear) => {
            const [year, month] = monthYear.split('-');
            const reports = groupedReports[monthYear].reports;
            return (
              <RecommendedRestockReportGroupTableRow
                key={monthYear}
                month={parseInt(month)}
                year={parseInt(year)}
                reports={reports}
              />
            );
          })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
