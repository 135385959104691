import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import { StockAdjustmentType } from '../../../domain/automator/stock/StockAdjustmentType';
import StockAdjustment from '../../../domain/automator/stock/StockAdjustment';
import { InventoryItemStockAdjustmentsQueryKey } from '../../queryKeys/InventoryItemStockAdjustmentsQueryKey';

export const useFetchInventoryItemStockAdjustments = (
  inventoryItemId: number,
  type: StockAdjustmentType
) => {
  const url = useAutomatorApiAccountUrl(
    INVENTORY_ITEMS + `/${inventoryItemId}/stock-adjustments?type=${type}`
  );
  return useFetchData<StockAdjustment[]>(
    url,
    InventoryItemStockAdjustmentsQueryKey(inventoryItemId, type)
  );
};
