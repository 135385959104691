import ActionButton from '../../../../components/ActionButton';
import { useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import InventoryItem from '../../../../domain/automator/stock/InventoryItem';
import SalesVariant from '../../../../domain/automator/stock/SalesVariant';
import { useRemoveSalesVariantFromInventoryItem } from '../../../../api/automator/stock/useRemoveSalesVariantFromInventoryItem';

interface RemoveSalesVariantFromInventoryItemFormProps {
  inventoryItem: InventoryItem;
  salesVariant: SalesVariant;
  onClick?: () => void;
}

export const RemoveSalesVariantFromInventoryItemForm = ({
  inventoryItem,
  salesVariant,
  onClick,
}: RemoveSalesVariantFromInventoryItemFormProps) => {
  const [quantity, setQuantity] = useState<number | null>(null);

  const { t } = useTranslation();
  const { mutate: doRemoveSalesVariant, isLoading } = useRemoveSalesVariantFromInventoryItem(
    salesVariant.inventoryItemId
  );

  const onRemoveSalesVariant = () => {
    if (quantity === null) {
      toast.error(t(tokens.automator.stock.remove_sales_variant_dialog.please_enter_stock_to_move));
      return;
    }

    if (quantity > inventoryItem.currentStock) {
      toast.error(
        t(tokens.automator.stock.remove_sales_variant_dialog.stock_to_move_exceeds_current_stock)
      );
      return;
    }

    doRemoveSalesVariant(
      { sales_variant_id: salesVariant.id, stock_to_move: quantity },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.stock.remove_sales_variant_dialog.remove_sales_variant));
          onClick?.();
        },
      }
    );
  };

  return (
    <Stack>
      <Stack
        direction="row"
        gap={2}
      >
        <TooltippedProductThumbnail
          thumbnailUrl={undefined}
          productTitle={salesVariant.title}
          quantity={inventoryItem.currentStock}
          height={100}
        />

        <Box width={300}>
          <TextField
            helperText={t(tokens.automator.stock.remove_sales_variant_dialog.remove_sales_variant)}
            label={t(tokens.automator.stock.remove_sales_variant_dialog.stock_to_move)}
            variant="outlined"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
          />
        </Box>
      </Stack>

      <ActionButton
        label={t(tokens.common.save)}
        onClick={onRemoveSalesVariant}
        isLoading={isLoading}
      />
    </Stack>
  );
};
