import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalInventoryItemDaysAverage } from './useGlobalInventoryItemDaysAverage';
import { RootState } from '../Store';
import { setItemDays } from './inventoryItemDaysAverageSlice';

export const useInventoryItemDaysAverage = (inventoryItemId: number) => {
  const dispatch = useDispatch();

  const { globalDays } = useGlobalInventoryItemDaysAverage();

  const itemDayValue = useSelector(
    (state: RootState) => state.inventory_item_days_average.itemDays[inventoryItemId]
  ) as 7 | 14 | 30 | undefined;

  const effectiveDays = itemDayValue === undefined ? globalDays : itemDayValue;

  const updateItemDays = useCallback(
    (days: 7 | 14 | 30) => {
      dispatch(setItemDays({ itemId: inventoryItemId, days }));
    },
    [dispatch, inventoryItemId]
  );

  return {
    days: effectiveDays,
    updateItemDays,
  };
};
