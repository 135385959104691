import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useFetchProductGroups } from '../../../../api/automator/products/useFetchProductGroups';

interface ProductGroupDropdownProps {
  selected: number | null;
  onSelect: (supplyChainPhaseId: number) => void;
}

export const ProductGroupDropdown = ({ onSelect, selected }: ProductGroupDropdownProps) => {
  const { data: productGroups, isLoading: isLoadingFetch } = useFetchProductGroups();

  const { t } = useTranslation();

  if (isLoadingFetch) {
    return <div>Loading...</div>;
  }

  const options = productGroups!.map((productGroup) => ({
    key: productGroup.id,
    value: productGroup.name,
  }));

  return (
    <TextField
      label={t(tokens.automator.products.groups)}
      select
      value={selected}
      SelectProps={{ native: true }}
      onChange={(e) => onSelect(e.target.value as unknown as number)}
    >
      {options.map((option) => (
        <option
          key={option.key}
          value={option.key}
        >
          {option.value}
        </option>
      ))}
    </TextField>
  );
};
