import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { EditRestockForm } from './EditRestockForm';

export const EditRestockDialog = () => {
  const id = DialogId.EDIT_RESTOCK;

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'xl'}
          title={t(tokens.automator.stock.edit_restock_dialog.edit_restock)}
        >
          <EditRestockForm
            restockId={data.restockId}
            onSave={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface EditRestockData {
  restockId: number;
}
