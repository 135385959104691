import { PanTool } from '@mui/icons-material';
import ActionButton from '../../../../components/ActionButton';
import { useReturnItemOnHold } from '../../../../api/automator/returns/useReturnItemOnHold';
import { useReturnItemSetToNew } from '../../../../api/automator/returns/useReturnItemSetToNew';
import { ReturnItemStatus } from '../../../../domain/automator/returns/ReturnItemStatus';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface OnHoldActionButtonProps {
  returnItemId: number;
  currentStatus: ReturnItemStatus;
  showLabel?: boolean;
  onSuccess?: () => void;
}

const OnHoldActionButton = ({
  returnItemId,
  currentStatus,
  showLabel,
  onSuccess,
}: OnHoldActionButtonProps) => {
  const { t } = useTranslation();
  const { mutate: returnItemOnHold, isLoading: returnItemOnHoldIsLoading } =
    useReturnItemOnHold(returnItemId);
  const { mutate: returnItemNew, isLoading: returnItemNewLoading } =
    useReturnItemSetToNew(returnItemId);

  const handleClick = () => {
    if (currentStatus !== ReturnItemStatus.ON_HOLD) {
      returnItemOnHold(undefined, {
        onSuccess: async () => {
          toast.success(t(tokens.automator.returns.actions.set_to_on_hold));
          onSuccess?.();
        },
      });
    } else {
      returnItemNew(undefined, {
        onSuccess: async () => {
          toast.success(t(tokens.automator.returns.actions.set_to_new));
          onSuccess?.();
        },
      });
    }
  };

  return (
    <ActionButton
      icon={<PanTool />}
      label={showLabel ? t(tokens.automator.returns.actions.set_to_on_hold) : ''}
      onClick={handleClick}
      isDisabled={
        currentStatus === ReturnItemStatus.REPAIR || currentStatus === ReturnItemStatus.EXCHANGE
      }
      isLoading={returnItemOnHoldIsLoading || returnItemNewLoading}
      variant={currentStatus === ReturnItemStatus.ON_HOLD ? 'contained' : 'outlined'}
      size="small"
    />
  );
};

export default OnHoldActionButton;
