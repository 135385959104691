import { EndpointQueryKey } from './EndpointQueryKey';
import { OrderStatus } from '../../domain/automator/orders/OrderStatus';
import { DistributionParty } from '../../domain/automator/orders/DistributionParty';

export const OrdersQueryKey = (
  status: OrderStatus,
  distributionParty?: DistributionParty,
  startShipmentDate?: string | null,
  endShipmentDate?: string | null,
  productIds?: number[]
) => {
  return (
    EndpointQueryKey.ORDERS +
    '_' +
    status +
    '_' +
    distributionParty +
    '_' +
    startShipmentDate +
    '_' +
    endShipmentDate +
    '_' +
    productIds?.join(',')
  );
};
