import { EndpointQueryKey } from './EndpointQueryKey';
import { StockAdjustmentType } from '../../domain/automator/stock/StockAdjustmentType';

export const InventoryItemStockAdjustmentsQueryKey = (
  inventoryItemId?: number,
  type?: StockAdjustmentType
) => {
  if (inventoryItemId === undefined) {
    return [EndpointQueryKey.INVENTORY_ITEM_STOCK_ADJUSTMENTS];
  }

  return [EndpointQueryKey.INVENTORY_ITEM_STOCK_ADJUSTMENTS, { productId: inventoryItemId, type }];
};
