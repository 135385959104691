import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { UpdateSupplierForm } from './SupplierForm';
import Supplier from '../../../../domain/automator/stock/Supplier';
import { useCreateSupplier } from '../../../../api/automator/stock/useCreateSupplier';
import { toast } from 'react-hot-toast';

export const CreateSupplierDialog = () => {
  const id = DialogId.CREATE_SUPPLIER;

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  const { mutate: doCreate, isLoading } = useCreateSupplier();

  const onSave = (supplier: Supplier) => {
    doCreate(
      {
        inventory_item_id: data?.inventoryItemId,
        contact_name: supplier.contactName,
        company_name: supplier.companyName,
        hs_code: supplier.hsCode,
        email_address: supplier.emailAddress,
        url: supplier.url,
      },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.stock.supplier_dialog.supplier_created));
        },
      }
    );
    closeDialog();
  };

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'sm'}
          title={t(tokens.automator.stock.supplier_dialog.supplier)}
        >
          <UpdateSupplierForm
            onSave={onSave}
            isLoading={isLoading}
            supplier={null}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface CreateSupplierDialogData {
  inventoryItemId?: number;
}
