import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { tokens } from '../../../../locales/translationTokens';
import { EditRestockItem, RestockItemForm, RestockItemMetricOption } from './RestockItemForm';
import { toast } from 'react-hot-toast';
import { RestockItemStatus } from '../../../../domain/automator/stock/RestockItemStatus';
import RestockItem from '../../../../domain/automator/stock/RestockItem';
import { useUpdateRestockItem } from '../../../../api/automator/stock/useUpdateRestockItem';
import { useState } from 'react';
import ActionButton from '../../../../components/ActionButton';

interface UpdateRestockItemFormProps {
  onClick?: () => void;
  restockItem: RestockItem;
}

export const UpdateRestockItemForm = ({ restockItem, onClick }: UpdateRestockItemFormProps) => {
  const initial = () => {
    return {
      // NOTE: hacky way to be able to use the same form as the restock form
      inventoryItem: {
        id: restockItem.stockValues.inventoryItemId,
        supplierIds: [],
        salesVariants: [],
      },
      supplierId: null,
      quantity: restockItem.stockValues.quantity,
      unitPriceInCents: restockItem.stockValues.unitPriceInCents,
      unitTransportCostInCents: restockItem.stockValues.unitTransportCostInCents,
      totalCubicMeters: restockItem.stockValues.totalCubicMeters,
      totalWeightInKg: restockItem.stockValues.totalWeightInKg,
      status: RestockItemStatus.DELIVERED,
      daysUntilDelivery: 0,
      metricOption: RestockItemMetricOption.BY_UNIT,
    };
  };

  const [item, setItem] = useState<EditRestockItem>(initial());

  const { mutate: updateRestockItem } = useUpdateRestockItem(restockItem.id);

  const { t } = useTranslation();

  const onSave = () => {
    updateRestockItem(
      {
        supplier_id: item.supplierId,
        quantity: item.quantity,
        unit_price_in_cents:
          item.metricOption == RestockItemMetricOption.TOTAL && item.unitPriceInCents
            ? item.unitPriceInCents / item.quantity
            : item.unitPriceInCents,
        unit_transport_cost_in_cents:
          item.metricOption == RestockItemMetricOption.TOTAL && item.unitTransportCostInCents
            ? item.unitTransportCostInCents / item.quantity
            : item.unitTransportCostInCents,
        total_cubic_meters:
          item.metricOption == RestockItemMetricOption.TOTAL && item.totalCubicMeters
            ? item.totalCubicMeters / item.quantity
            : item.totalCubicMeters,
        total_weight_in_kg:
          item.metricOption == RestockItemMetricOption.TOTAL && item.totalWeightInKg
            ? item.totalWeightInKg / item.quantity
            : item.totalWeightInKg,
        expected_delivery_date: new Date(
          new Date().getTime() + item.daysUntilDelivery * 24 * 60 * 60 * 1000
        ).toISOString(),
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.stock.update_restock_item.restock_item_updated));
        },
      }
    );
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <RestockItemForm
        supplierId={null}
        item={item}
        onUpdate={(item) => setItem(item)}
        canUpdateQuantity={restockItem.inventoryStockId == null}
      />

      <ActionButton
        color="primary"
        label={t(tokens.automator.stock.restock_dialog.save)}
        onClick={() => onSave && onSave()}
      />
    </Stack>
  );
};
