import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import ReturnItem from '../../../../domain/automator/returns/ReturnItem';
import AcceptReturnItemButton from './AcceptReturnItemButton';
import RejectReturnItemButton from './RejectReturnItemButton';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import ActionButton from '../../../../components/ActionButton';

interface HandleReturnItemButtonProps {
  returnItem: ReturnItem;
  type: 'ACCEPT' | 'REJECT';
}

const HandleReturnItemButton = ({ returnItem, type }: HandleReturnItemButtonProps) => {
  const openDialog = useOpenDialog(DialogId.HANDLE_RETURN_ITEM);

  const { t } = useTranslation();

  if (returnItem.quantityUnprocessed > 1) {
    return (
      <ActionButton
        style={{ backgroundColor: type == 'ACCEPT' ? '#10B981' : '#F04438' }}
        icon={
          type == 'ACCEPT' ? (
            <ThumbUp style={{ color: 'WHITE' }} />
          ) : (
            <ThumbDown style={{ color: 'WHITE' }} />
          )
        }
        label={
          type == 'ACCEPT'
            ? t(tokens.automator.returns.actions.accept)
            : t(tokens.automator.returns.actions.reject)
        }
        onClick={() => {
          openDialog({
            returnItem: returnItem,
            type: type,
          });
        }}
      />
    );
  } else {
    if (type == 'ACCEPT') {
      return (
        <AcceptReturnItemButton
          returnItem={returnItem}
          quantityReturned={1}
        />
      );
    } else {
      return (
        <RejectReturnItemButton
          returnItem={returnItem}
          quantityReturned={1}
        />
      );
    }
  }
};

export default HandleReturnItemButton;
