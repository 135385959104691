import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import InventoryItemInsight from '../../../domain/automator/stock/InventoryItemInsight';
import { InventoryItemInsightQueryKey } from '../../queryKeys/InventoryItemInsightQueryKey';

export const useFetchInventoryItemInsight = (inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(`${INVENTORY_ITEMS}/${inventoryItemId}/insight`);

  return useFetchData<InventoryItemInsight>(url, InventoryItemInsightQueryKey(inventoryItemId));
};
