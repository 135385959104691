import { SUPPLIERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { SuppliersQueryKey } from '../../queryKeys/SuppliersQueryKey';
import Supplier from '../../../domain/automator/stock/Supplier';
import { InventoryItemSuppliersQueryKey } from '../../queryKeys/InventoryItemSuppliersQueryKey';

export const useCreateSupplier = () => {
  const url = useAutomatorApiAccountUrl(SUPPLIERS_PATH);
  return useAuthorizedPost<Supplier, CreateSupplierData>(url, [
    SuppliersQueryKey(),
    InventoryItemSuppliersQueryKey(),
  ]);
};

export interface CreateSupplierData {
  inventory_item_id?: number;
  contact_name: string;
  company_name: string;
  hs_code: string;
  email_address: string;
  url: string;
}
