import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { INVENTORY_ITEMS } from '../automatorApiPaths';
import Return from '../../../domain/automator/returns/Return';
import { ReturnsByInventoryItemIdQueryKey } from '../../queryKeys/ReturnsByInventoryItemIdQueryKey';

export const useFetchReturnsByInventoryItemId = (inventoryItemId: number) => {
  const url = useAutomatorApiAccountUrl(INVENTORY_ITEMS + '/' + inventoryItemId + '/returns');
  return useFetchData<Return[]>(url, ReturnsByInventoryItemIdQueryKey(inventoryItemId));
};
