import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { SupplyChainPhaseType } from '../../../../domain/automator/stock/SupplyChainPhaseType';
import SupplyChainPhase from '../../../../domain/automator/stock/SupplyChainPhase';

interface InventoryItemSupplyChainPhaseSettingProps {
  title: string;
  isUsingPhase: boolean;
  onIsUsingPhaseChange: (value: boolean) => void;
  supplyChainPhases: SupplyChainPhase[];
  selectedPhaseId: number | null;
  onSelectedPhaseIdChange: (value: number | null) => void;
  days: number;
  onDaysChange: (value: number) => void;
  filterPhaseType: SupplyChainPhaseType;
}

export const InventoryItemSupplyChainPhaseSetting = ({
  title,
  isUsingPhase,
  onIsUsingPhaseChange,
  supplyChainPhases,
  selectedPhaseId,
  onSelectedPhaseIdChange,
  days,
  onDaysChange,
  filterPhaseType,
}: InventoryItemSupplyChainPhaseSettingProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="flex-end"
    >
      <Stack
        direction="column"
        gap={1}
      >
        <Typography
          fontSize={20}
          fontWeight={600}
          variant="body1"
        >
          {title}
        </Typography>

        <RadioGroup
          row
          value={isUsingPhase ? 'phase' : 'days'}
          onChange={(e) => onIsUsingPhaseChange(e.target.value === 'phase')}
        >
          <FormControlLabel
            value="phase"
            control={<Radio />}
            label={t(tokens.automator.stock.settings.supply_chain_phases.use_phase)}
          />
          <FormControlLabel
            value="days"
            control={<Radio />}
            label={t(tokens.automator.stock.settings.supply_chain_phases.use_custom_days)}
          />
        </RadioGroup>
      </Stack>

      <Stack
        direction="row"
        gap={1}
      >
        {isUsingPhase ? (
          supplyChainPhases
            .filter((phase) => phase.type === filterPhaseType)
            .map((phase) => (
              <ActionButton
                key={phase.id}
                label={
                  phase.name +
                  ' (' +
                  phase.durationInDays +
                  ' ' +
                  t(tokens.automator.stock.settings.supply_chain_phases.days) +
                  ')'
                }
                size="small"
                variant={selectedPhaseId === phase.id ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => onSelectedPhaseIdChange(phase.id)}
              />
            ))
        ) : (
          <TextField
            type="number"
            label={t(tokens.automator.stock.settings.supply_chain_phases.days)}
            value={days}
            onChange={(e) => onDaysChange(Number(e.target.value))}
          />
        )}
      </Stack>
    </Stack>
  );
};
