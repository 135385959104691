import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import RestockItem from '../../../../domain/automator/stock/RestockItem';
import { UpdateRestockItemForm } from './UpdateRestockItemForm';

export const UpdateRestockItemDialog = () => {
  const id = DialogId.UPDATE_RESTOCK_ITEM;

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'md'}
          title={t(tokens.automator.stock.update_restock_item.update_restock_item)}
        >
          <UpdateRestockItemForm
            restockItem={data.restockItem}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateRestockItemDialogData {
  restockItem: RestockItem;
}
