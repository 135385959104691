import { SUPPLY_CHAIN_PHASES } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Restock from '../../../domain/automator/stock/Restock';
import { SupplyChainPhasesQueryKey } from '../../queryKeys/SupplyChainPhasesQueryKey';
import { SupplyChainPhaseType } from '../../../domain/automator/stock/SupplyChainPhaseType';

export const useCreateSupplyChainPhase = () => {
  const url = useAutomatorApiAccountUrl(SUPPLY_CHAIN_PHASES);

  return useAuthorizedPost<Restock, CreateSupplyChainPhaseData>(url, [SupplyChainPhasesQueryKey()]);
};

export interface CreateSupplyChainPhaseData {
  name: string;
  duration_in_days: number;
  type: SupplyChainPhaseType;
}
